import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import Header from "./Header/Header";
import Footer from "./footer/Footer";

const theme = createTheme({
      palette: {
            primary: {
                  // Purple and green play nicely together.
                  main: purple[500],
            },
            secondary: {
                  // This is green.A700 as hex.
                  main: "#11cb5f",
            },
      },
});

const TablaContrato = () => {
      const [contrato, setContrato] = useState([]);
      const [mascotas, setMascotas] = useState([]);
      const { num } = useParams();

      theme.typography.h5 = {
            fontSize: "1.4rem",
            "@media (max-width:600px)": {
                  fontSize: "0.8rem",
            },
      };

      theme.typography.h6 = {
            fontSize: "1.3rem",
            "@media (max-width:600px)": {
                  fontSize: "0.7rem",
            },
      };

      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };

      useEffect(() => {
            traerContrato();
            traerMascotas();
      }, []);

      const traerContrato = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/obtenerContrato/${num}`
            )
                  .then((res) => res.json())
                  .then((res) => setContrato(res));
      };

      const traerMascotas = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/obtenerMascotas/${num}`
            )
                  .then((res) => res.json())
                  .then((res) => setMascotas(res));
      };

      const generarPDF = () => {
            printDocument();
            setTimeout(() => {
                  window.location = "/es";
            }, 3000);
      };

      const printDocument = () => {
            const pdf = new jsPDF("p", "mm", "a4");
            const input = document.getElementById("content");
            html2canvas(input).then((canvas) => {
                  let imgWidth = 208;
                  let imgHeight = (canvas.height * imgWidth) / canvas.width;
                  const imgData = canvas.toDataURL("image/jpeg", 1.0);
                  pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
                  pdf.addPage();
                  pdf.addImage(
                        process.env.PUBLIC_URL + "/images/clausula.jpeg",
                        "JPEG",
                        0,
                        0,
                        208,
                        250
                  );
                  pdf.save(`${contrato.map((c) => c.Nombres)}_${num}.pdf`);
            });
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      return (
            <>
                  <Header />
                  <ThemeProvider theme={theme}>
                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              alignItems="center"
                              style={{ marginTop: "70px" }}
                        >
                              <div style={{ marginTop: 15 }}>
                                    <Button
                                          onClick={() => generarPDF()}
                                          style={{
                                                color: "white",
                                                background: "#ff0000",
                                          }}
                                          variant="contained"
                                    >
                                          Generar PDF
                                    </Button>
                              </div>
                        </Grid>
                        <div
                              id="content"
                              style={{ paddingTop: 150, marginBottom: "20px" }}
                        >
                              {contrato.map((con) => (
                                    <Container>
                                          <Grid
                                                container
                                                justifyContent="center"
                                                md={12}
                                                alignItems="center"
                                          >
                                                <Grid
                                                      container
                                                      justifyContent="space-between"
                                                      md={11}
                                                      sm={12}
                                                >
                                                      <Grid
                                                            item
                                                            xs={6}
                                                            sm={3}
                                                            md={4}
                                                      >
                                                            <img
                                                                  src={
                                                                        process
                                                                              .env
                                                                              .PUBLIC_URL +
                                                                        "/images/logoEmpresa.png"
                                                                  }
                                                                  width="100%"
                                                            />
                                                            <div
                                                                  style={{
                                                                        width: "100%",
                                                                  }}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              NIT
                                                                              :
                                                                              901365548
                                                                              -
                                                                              9
                                                                        </b>
                                                                  </Typography>
                                                            </div>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            md={3}
                                                            style={{
                                                                  paddingTop:
                                                                        "20px",
                                                            }}
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        Oficina
                                                                        Urbanización
                                                                        La
                                                                        Castilla,
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        Cl. 11 B
                                                                        #2J –
                                                                        46,
                                                                        Florencia,
                                                                        Caquetá
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        3152533653
                                                                        -
                                                                        3123881225
                                                                  </b>
                                                            </Typography>
                                                      </Grid>

                                                      <Grid
                                                            container
                                                            justifyContent="center"
                                                            md={12}
                                                            style={{
                                                                  marginTop:
                                                                        "20px",
                                                            }}
                                                      >
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        CONTRATO
                                                                        DE
                                                                        AFILIACIÓN
                                                                        INDIVIDUAL
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                      <Grid item md={5}>
                                                            <table className="table table-bordered table-sm mt-5">
                                                                  <thead>
                                                                        <tr>
                                                                              <th
                                                                                    colSpan="6"
                                                                                    style={{
                                                                                          color: "white",
                                                                                          background:
                                                                                                "black",
                                                                                    }}
                                                                              >
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          <b>
                                                                                                VIGENCIA
                                                                                          </b>
                                                                                    </Typography>
                                                                              </th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                        <tr>
                                                                              <th
                                                                                    colSpan="3"
                                                                                    span="COL"
                                                                                    style={{
                                                                                          color: "white",
                                                                                          background:
                                                                                                "black",
                                                                                    }}
                                                                              >
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          <b>
                                                                                                {" "}
                                                                                                FECHA
                                                                                                INICIAL{" "}
                                                                                          </b>
                                                                                    </Typography>
                                                                              </th>
                                                                              <th
                                                                                    colSpan="3"
                                                                                    span="COL"
                                                                                    style={{
                                                                                          color: "white",
                                                                                          background:
                                                                                                "black",
                                                                                    }}
                                                                              >
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          <b>
                                                                                                FECHA
                                                                                                RENOVACIÓN
                                                                                          </b>
                                                                                    </Typography>
                                                                              </th>
                                                                        </tr>
                                                                        <tr>
                                                                              <th span="COL">
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                con.DIA_FecIni
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                              <th span="COL">
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                con.MES_FecIni
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                              <th span="COL">
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                con.YEAR_FecIni
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                              <th span="COL">
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                con.DIA_FecRen
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                              <th span="COL">
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                con.MES_FecRen
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                              <th span="COL">
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                con.YEAR_FecRen
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                        </tr>
                                                                  </tbody>
                                                            </table>
                                                      </Grid>

                                                      <Grid item md={4}>
                                                            <table className="table table-bordered table-sm mt-5">
                                                                  <tbody>
                                                                        <tr>
                                                                              <th
                                                                                    span="COL"
                                                                                    style={{
                                                                                          color: "white",
                                                                                          background:
                                                                                                "black",
                                                                                    }}
                                                                              >
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          <b>
                                                                                                CONTRATO
                                                                                          </b>
                                                                                    </Typography>
                                                                              </th>
                                                                              <th span="COL">
                                                                                    <Typography
                                                                                          variant="h6"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                          style={{
                                                                                                color: "#ff0000",
                                                                                          }}
                                                                                    >
                                                                                          <b>
                                                                                                N°{" "}
                                                                                                {
                                                                                                      con.NoContrato
                                                                                                }
                                                                                          </b>
                                                                                    </Typography>
                                                                              </th>
                                                                        </tr>
                                                                  </tbody>
                                                            </table>
                                                      </Grid>
                                                </Grid>
                                                <Grid
                                                      container
                                                      justifyContent="center"
                                                      mt={3}
                                                      md={11}
                                                      sm={12}
                                                >
                                                      <table className="table table-bordered table-sm  mt-5">
                                                            <thead>
                                                                  <tr>
                                                                        <th colSpan="5">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          INFORMACIÓN
                                                                                          DEL
                                                                                          TITULAR
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </thead>

                                                            <tbody>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="3"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          NOMBRES
                                                                                          Y
                                                                                          APELLIDOS:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.Nombres
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          C.C{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.Cedula
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          FECHA
                                                                                          DE
                                                                                          NACIMIENTO:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.FecNacimiento
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          SEXO:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.Sexo
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          ESTADO
                                                                                          CIVIL:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.EstadoCivil
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="3"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          DIRECCIÓN
                                                                                          DE
                                                                                          RESIDENCIA:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.Direccion
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          TELEFÓNO:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.Telefono
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="3"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          EMAIL:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.Email
                                                                                    }
                                                                              </Typography>
                                                                        </th>

                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          FORMA
                                                                                          DE
                                                                                          PAGO:{" "}
                                                                                    </b>
                                                                                    {
                                                                                          con.formaPago
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>

                                                                  <tr>
                                                                        <th
                                                                              colSpan="3"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          VALOR
                                                                                          TOTAL
                                                                                          DEL
                                                                                          PLAN
                                                                                          :{" "}
                                                                                    </b>{" "}
                                                                                    {formatterPeso.format(
                                                                                          con.valorTotal
                                                                                    )}
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          CUOTA
                                                                                          INICIAL{" "}
                                                                                    </b>
                                                                                    {formatterPeso.format(
                                                                                          con.cuotaInicial
                                                                                    )}
                                                                                    <b>
                                                                                          {" "}
                                                                                          y{" "}
                                                                                    </b>
                                                                                    {con.y -
                                                                                          1}
                                                                                    <b>
                                                                                          {" "}
                                                                                          cuotas
                                                                                          de{" "}
                                                                                    </b>
                                                                                    {formatterPeso.format(
                                                                                          con.valorCuotas
                                                                                    )}
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="5"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                              >
                                                                                    <b>
                                                                                          DIA
                                                                                          DE
                                                                                          PAGO
                                                                                          :{" "}
                                                                                    </b>
                                                                                    Los
                                                                                    días{" "}
                                                                                    <b>
                                                                                          {
                                                                                                con.DiaPago
                                                                                          }
                                                                                    </b>{" "}
                                                                                    de
                                                                                    cada
                                                                                    mes
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </tbody>
                                                      </table>

                                                      <table className="table table-bordered table-sm mt-5">
                                                            <thead>
                                                                  <tr>
                                                                        <th colSpan="9">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          MASCOTAS
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </thead>

                                                            <tbody>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          No.
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          NOMBRE
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          TIPO
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          RAZA
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          SEXO
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          COLOR
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>

                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          EDAD
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          PLAN
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          VALOR
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                                  {mascotas.map(
                                                                        (
                                                                              mascota
                                                                        ) => (
                                                                              <tr>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.No
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.Nombre
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.Tipo
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.Raza
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.Sexo
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.Color
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>

                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.Edad
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      mascota.Plan
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {formatterPeso.format(
                                                                                                      mascota.Valor
                                                                                                )}
                                                                                          </Typography>
                                                                                    </th>
                                                                              </tr>
                                                                        )
                                                                  )}
                                                            </tbody>
                                                      </table>

                                                      <table className="table table-bordered table-sm  mt-5">
                                                            <thead>
                                                                  <tr>
                                                                        <th colSpan="7">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          OBSERVACIONES
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </thead>

                                                            <tbody>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="7"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="justify"
                                                                              >
                                                                                    {
                                                                                          con.Observaciones
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </tbody>
                                                      </table>

                                                      <table
                                                            className="table table-bordered table-sm "
                                                            style={{
                                                                  marginTop:
                                                                        "100px",
                                                            }}
                                                      >
                                                            <tbody>
                                                                  <tr>
                                                                        <th colSpan="3">
                                                                              <div
                                                                                    style={{
                                                                                          width: "100%",
                                                                                          height: "100px",
                                                                                          display: "flex",
                                                                                          justifyContent:
                                                                                                "center",
                                                                                          alignItems:
                                                                                                "end",
                                                                                    }}
                                                                              >
                                                                                    <div
                                                                                          style={{
                                                                                                width: "250px",
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                      "center",
                                                                                                alignItems:
                                                                                                      "end",
                                                                                          }}
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h4"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      {
                                                                                                            con.Nombres
                                                                                                      }
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </div>
                                                                              </div>
                                                                              <hr
                                                                                    style={{
                                                                                          background:
                                                                                                "black",
                                                                                          marginBottom: 0,
                                                                                          marginTop: 0,
                                                                                    }}
                                                                              ></hr>
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          FIRMA
                                                                                          DEL
                                                                                          TITULAR
                                                                                    </b>
                                                                              </Typography>
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    component="h2"
                                                                                    align="start"
                                                                              >
                                                                                    <b>
                                                                                          CC.
                                                                                    </b>
                                                                                    {
                                                                                          con.Cedula
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                        <th colSpan="3">
                                                                              <div
                                                                                    style={{
                                                                                          width: "100%",
                                                                                          height: "100px",
                                                                                          display: "flex",
                                                                                          justifyContent:
                                                                                                "center",
                                                                                          alignItems:
                                                                                                "end",
                                                                                    }}
                                                                              >
                                                                                    <div
                                                                                          style={{
                                                                                                width: "250px",
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                      "center",
                                                                                                alignItems:
                                                                                                      "end",
                                                                                          }}
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h4"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      {
                                                                                                            con.User
                                                                                                      }
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </div>
                                                                              </div>
                                                                              <hr
                                                                                    style={{
                                                                                          background:
                                                                                                "black",
                                                                                          marginBottom: 0,
                                                                                          marginTop: 0,
                                                                                    }}
                                                                              ></hr>
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          FIRMA
                                                                                          DEL
                                                                                          ASESOR(A)
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </tbody>
                                                      </table>
                                                </Grid>
                                          </Grid>
                                    </Container>
                              ))}
                        </div>
                  </ThemeProvider>
                  <Footer />
            </>
      );
};
export default TablaContrato;
