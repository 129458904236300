import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useNavigate, useParams } from "react-router-dom";
import FormContratoMascotas from "./formMascotas";
import FormContratoPrenecesidad from "./formMascotasPrenecesidad";
import StarOutlineIcon from "@material-ui/icons/StarOutline";

function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
            <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`nav-tabpanel-${index}`}
                  aria-labelledby={`nav-tab-${index}`}
                  {...other}
            >
                  {value === index && (
                        <Box p={3}>
                              <Typography>{children}</Typography>
                        </Box>
                  )}
            </div>
      );
}

TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
};

function a11yProps(index) {
      return {
            id: `nav-tab-${index}`,
            "aria-controls": `nav-tabpanel-${index}`,
      };
}

function LinkTab(props) {
      return (
            <Tab
                  component="a"
                  onClick={(event) => {
                        event.preventDefault();
                  }}
                  {...props}
            />
      );
}

const useStyles = makeStyles((theme) => ({
      root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
      },
}));

export default function Opciones() {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const classes = useStyles();
      const [value, setValue] = useState(0);
      const { cc } = useParams();

      useEffect(() => {
            if (!token) {
                  navigate("/");
            }
      }, []);

      const handleChange = (event, newValue) => {
            setValue(newValue);
      };

      return (
            <div className={classes.root}>
                  <AppBar
                        position="static"
                        style={{ background: "#ff0000", color: "white" }}
                  >
                        <Tabs
                              variant="fullWidth"
                              value={value}
                              onChange={handleChange}
                              aria-label="nav tabs example"
                        >
                              <LinkTab
                                    icon={<StarOutlineIcon />}
                                    label="PLAN"
                                    href={`/mascotas/${cc}`}
                                    {...a11yProps(0)}
                              />
                              <LinkTab
                                    icon={<StarOutlineIcon />}
                                    label="PLAN PRENECESIDAD"
                                    href={`/prenecesidad/${cc}`}
                                    {...a11yProps(1)}
                              />
                        </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                        <FormContratoMascotas />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                        <FormContratoPrenecesidad />
                  </TabPanel>
            </div>
      );
}
