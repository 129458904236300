import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import "./dashboard.css";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";

const Dashboard = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();

      useEffect(() => {
            if (!token) {
                  navigate("/");
            }
      }, []);

      const ventanas = [
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0L24 0 24 24 0 24z" />
                              <path
                                    d="M20 2c.552 0 1 .448 1 1v3.757l-2 2V4H5v16h14v-2.758l2-2V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h16zm1.778 6.808l1.414 1.414L15.414 18l-1.416-.002.002-1.412 7.778-7.778zM13 12v2H8v-2h5zm3-4v2H8V8h8z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "AFILIACIONES",
                  text: "Agregar nuevos afiliados",
                  url: "/afiliaciones",
            },
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                    d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "CLIENTES",
                  text: "Consulta de todos los clientes y de cobros",
                  url: "/gestion",
            },
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                    d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "LABOR DIARIA",
                  text: "Labor de Cobros Hoy",
                  url: "/labor",
            },
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.5-6H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V6h2v2h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "COBROS PARA HOY",
                  text: "Gestión de Cobros",
                  url: "/cobros",
            },
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                    d="M11 7h2v10h-2V7zm4 4h2v6h-2v-6zm-8 2h2v4H7v-4zm8-9H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "REPORTE X DIAS",
                  text: "Reportes de los Cobros Diarios",
                  url: "/todospagos",
            },
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0H24V24H0z" />
                              <path
                                    d="M5 3v16h16v2H3V3h2zm14.94 2.94l2.12 2.12L16 14.122l-3-3-3.94 3.94-2.12-2.122L13 6.88l3 3 3.94-3.94z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "GRAFICAS",
                  text: "Informe de Gráficas Estadisticas",
                  url: "/graficas",
            },
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                    d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM11 10V7h2v3h3v2h-3v3h-2v-3H8v-2h3z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "FALLECIDOS",
                  text: "Mascotas Fallecidas",
                  url: "/fallecidos",
            },
            {
                  icon: (
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                        >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                    d="M11.39 10.39L7.5 14.277 3.61 10.39a5.5 5.5 0 1 1 7.78 0zM7.5 8.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm12.89 10.89l-3.89 3.888-3.89-3.889a5.5 5.5 0 1 1 7.78 0zM16.5 17.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                    fill="rgba(255,0,0,1)"
                              />
                        </svg>
                  ),
                  title: "CEMENTERIO",
                  text: "Ubicación de mascotas en el cementerio",
                  url: "/mapa",
            },
      ];

      return (
            <>
                  <Header />
                  <section className="pt-5">
                        <Container>
                              <Row className="p-5">
                                    {ventanas.map((item) => (
                                          <Col
                                                lg="4"
                                                md="4"
                                                sm="12"
                                                xs="12"
                                                className="mb-4 "
                                                //key={item.id}
                                          >
                                                <div className="single_product">
                                                      {item.icon}
                                                      <h6>{item.title}</h6>
                                                      <p>{item.text}</p>
                                                      <div className="d-flex align-items-center justify-content-center">
                                                            <button
                                                                  className="btn"
                                                                  onClick={() =>
                                                                        navigate(
                                                                              `${item.url}`
                                                                        )
                                                                  }
                                                            >
                                                                  ENTRAR
                                                            </button>
                                                      </div>
                                                </div>
                                          </Col>
                                    ))}
                              </Row>
                        </Container>
                  </section>
                  <Footer/>
            </>
      );
};

export default Dashboard;
