import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import { useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./footer/Footer";

export default function MapaFallecidos() {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();

      useEffect(() => {
            if (!token) {
                  navigate("/");
            }
      }, []);

      const images = [
            {
                  url: "https://w0.peakpx.com/wallpaper/535/950/HD-wallpaper-green-fields-beautifull-blue-colorfull-nature-sky.jpg",
                  title: "CAMPO A",
                  direccion: "/campoa",
                  width: "100%",
            },
            {
                  url: "https://w0.peakpx.com/wallpaper/535/950/HD-wallpaper-green-fields-beautifull-blue-colorfull-nature-sky.jpg",
                  title: "CAMPO B",
                  direccion: "/campob",
                  width: "100%",
            },
            {
                  url: "https://w0.peakpx.com/wallpaper/535/950/HD-wallpaper-green-fields-beautifull-blue-colorfull-nature-sky.jpg",
                  title: "CAMPO C",
                  direccion: "/campoc",
                  width: "100%",
            },
            {
                  url: "https://w0.peakpx.com/wallpaper/535/950/HD-wallpaper-green-fields-beautifull-blue-colorfull-nature-sky.jpg",
                  title: "CAMPO D",
                  direccion: "/campod",
                  width: "100%",
            },
            {
                  url: "https://w0.peakpx.com/wallpaper/535/950/HD-wallpaper-green-fields-beautifull-blue-colorfull-nature-sky.jpg",
                  title: "CAMPO E",
                  direccion: "/campoe",
                  width: "100%",
            },
      ];

      const ImageButton = styled(ButtonBase)(({ theme }) => ({
            position: "relative",
            height: 200,
            [theme.breakpoints.down("sm")]: {
                  width: "100%", // Overrides inline-style
                  height: 200,
            },
            "&:hover, &.Mui-focusVisible": {
                  zIndex: 1,
                  "& .MuiImageBackdrop-root": {
                        opacity: 0.15,
                  },
                  "& .MuiImageMarked-root": {
                        opacity: 0,
                  },
                  "& .MuiTypography-root": {
                        border: "4px solid currentColor",
                  },
            },
      }));

      const ImageSrc = styled("span")({
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundSize: "cover",
            backgroundPosition: "center 40%",
      });

      const Image = styled("span")(({ theme }) => ({
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.common.white,
      }));

      const ImageBackdrop = styled("span")(({ theme }) => ({
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.palette.common.black,
            opacity: 0.4,
            transition: theme.transitions.create("opacity"),
      }));

      const ImageMarked = styled("span")(({ theme }) => ({
            height: 3,
            width: 18,
            backgroundColor: theme.palette.common.white,
            position: "absolute",
            bottom: -2,
            left: "calc(50% - 9px)",
            transition: theme.transitions.create("opacity"),
      }));

      return (
            <>
                  <Header />
                  <Container>
                        <Grid
                              container
                              xs={12}
                              sm={12}
                              md={12}
                              justifyContent="center"
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Typography
                                    variant="h5"
                                    align="center"
                                    style={{ marginTop: "12px" }}
                              >
                                    <b>CEMENTERIO</b>
                              </Typography>
                        </Grid>
                        <Grid
                              container
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ marginBottom: "20px" }}
                        >
                              {images.map((image) => (
                                    <Grid item sm={6} xm={6} md={3} p={2}>
                                          <ImageButton
                                                key={image.title}
                                                style={{
                                                      width: image.width,
                                                }}
                                                onClick={() =>
                                                      (window.location = `${image.direccion}`)
                                                }
                                          >
                                                <ImageSrc
                                                      style={{
                                                            backgroundImage: `url(${image.url})`,
                                                      }}
                                                />
                                                <ImageBackdrop className="MuiImageBackdrop-root" />
                                                <Image>
                                                      <Typography
                                                            component="span"
                                                            variant="subtitle1"
                                                            color="inherit"
                                                            sx={{
                                                                  position: "relative",
                                                                  p: 4,
                                                                  pt: 2,
                                                                  pb: (theme) =>
                                                                        `calc(${theme.spacing(
                                                                              1
                                                                        )} + 6px)`,
                                                            }}
                                                      >
                                                            {image.title}
                                                            <ImageMarked className="MuiImageMarked-root" />
                                                      </Typography>
                                                </Image>
                                          </ImageButton>
                                    </Grid>
                              ))}
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
}
