import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Formik, Form } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales/esES";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { Container, Grid, Typography, Button, Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DateFnsUtils from "@date-io/date-fns";
import "chart.js/auto";
import { useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./Header/Header";

const TodosPagosHoy = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [pagos, setPagos] = useState([]);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [size, setSize] = useState(0);
      const [cobros, setCobros] = useState("");
      const [gastos, setGastos] = useState("");
      const [diasCobrados, setDiasCobrados] = useState([]);
      const theme = createTheme();

      useEffect(() => {
            if (!token) {
                  navigate("/");
            }
      }, []);

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.7rem",
            },
      };
      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.8rem",
            },
      };

      const traerTodosPagos = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/todosPagosHoy/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);
                        setPagos(res);
                  });
      };

      const traerTotalCobros = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalPagosPorRango/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setCobros(r.TotalCobros));
                  });
      };

      const traerTotalGastos = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalGastosPorRango/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setGastos(r.TotalGastos));
                  });
      };

      const traerTotalDiasCobrados = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalPagosPorDiaMes/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => setDiasCobrados(res));
      };

      const columns = [
            {
                  field: "Fecha",
                  headerName: "Fecha",
                  width: 200,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{params.row.Fecha}</b>
                        </Stack>
                  ),
            },
            {
                  field: "Id",
                  headerName: "N° Recibo",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <b>{params.row.Id}</b>
                        </div>
                  ),
            },
            {
                  field: "Detalle",
                  headerName: "Detalle",
                  width: 320,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Valor",
                  headerName: "Valor",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{formatterPeso.format(params.row.Valor)}</b>
                        </Stack>
                  ),
            },
            {
                  field: "Nombres",
                  headerName: "Cliente",
                  width: 250,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "ElaboradoPor",
                  headerName: "Empleado",
                  width: 210,
                  headerAlign: "center",
            },
            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Ver Comprobante"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                (window.location = `/#/comprobanteindividual/${params.row.Id}`)
                                          }
                                    >
                                          <PictureAsPdfIcon />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const traerDatos = (values) => {
            traerTodosPagos(values);
            traerTotalCobros(values);
            traerTotalGastos(values);
            traerTotalDiasCobrados(values);
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Grid
                              container
                              md={12}
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                          variant="h5"
                                          component="h2"
                                          align="center"
                                          style={{
                                                marginTop: "12px",
                                                marginBottom: "12px",
                                          }}
                                    >
                                          <b>COMPROBANTES DE PAGO</b>
                                    </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={8}>
                                    <Formik
                                          initialValues={{
                                                Fecha1: null,
                                                Fecha2: null,
                                          }}
                                          onSubmit={(values, { resetForm }) => {
                                                traerDatos(values);
                                                //  resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <Grid
                                                            container
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="Fecha1"
                                                                              format="dd/MM/yyyy"
                                                                              margin="normal"
                                                                              label="Fecha 1*"
                                                                              //fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha1
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha1",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="Fecha2"
                                                                              format="dd/MM/yyyy"
                                                                              margin="normal"
                                                                              label="Fecha 2*"
                                                                              // fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha2
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha2",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>

                                                            <Grid item md={12}>
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                  >
                                                                        Filtrar
                                                                  </Button>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </Grid>
                              <Grid
                                    item
                                    styel={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                    }}
                                    xs={12}
                                    sm={12}
                                    md={4}
                              >
                                    <ThemeProvider theme={theme}>
                                          <table className="table table-bordered table-sm mt-2">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "#ff0000",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="4">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              INFORMACIÓN
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              INGRESOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {formatterPeso.format(
                                                                                    cobros
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              GASTOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {formatterPeso.format(
                                                                                    gastos
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              DIAS
                                                                              COBRADOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    diasCobrados.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </ThemeProvider>
                              </Grid>

                              <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{ marginTop: "20px" }}
                              >
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) => row.Id}
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                autoHeight
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Pagos por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={pagos}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default TodosPagosHoy;
