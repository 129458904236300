import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { Navigate, useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GestionContratos from "./gestionContratos";
import Footer from "./footer/Footer";
import Header from "./Header/Header";

const ComprobanteLaborDiaria = () => {
      const fecha = new Date();
      const fechaActual =
            fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate();
      const [gastos, setGastos] = useState([]);
      const [totalPagos, setTotalPagos] = useState("");
      const [totalGastos, setTotalGastos] = useState("");
      const [totalPagosEfectivo, setTotalPagosEfectivo] = useState("");
      const [totalPagosElectronicos, setTotalPagosElectronicos] = useState("");
      const [contrato, setContrato] = useState([]);
      const [pagos, setPagos] = useState([]);
      const [count, setCount] = useState(0);
      const { numero } = useParams();
      const totalNeto = totalPagos - totalGastos;
      const [noPagos, setNoPagos] = useState("");
      const [isAuth, setIsAuth] = useState(true);

      const theme = createTheme();

      theme.typography.h6 = {
            fontSize: "1.3rem",
            "@media (max-width:600px)": {
                  fontSize: "0.6rem",
            },
      };

      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.5rem",
            },
      };

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };
      useEffect(() => {
            traerTotalPagos();
            traerTotalGastos();
            traerTotalPagosEfectivo();
            traerTotalPagosElectronicos();
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/gastos/${fechaActual}`
            )
                  .then((res) => res.json())
                  .then((res) => setGastos(res));
      }, []);

      const traerTotalPagos = () => {
            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/pagos/totalPagosHoy"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map(
                              (r) => (
                                    setNoPagos(r.NO_PAGOS),
                                    setTotalPagos(r.TOTAL_PAGOS)
                              )
                        );
                  });
      };

      const traerTotalGastos = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/gastos/totalGastosHoy/${fechaActual}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalGastos(r.TOTAL_GASTOS));
                  });
      };

      const traerTotalPagosEfectivo = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/pagosEfectivo/${fechaActual}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalPagosEfectivo(r.PAGOS_EFECTIVO));
                  });
      };

      const traerTotalPagosElectronicos = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/pagosElectronicos/${fechaActual}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) =>
                              setTotalPagosElectronicos(r.PAGOS_ELECTRONICOS)
                        );
                  });
      };

      const generarPDF = () => {
            printDocument();
            setTimeout(() => {
                  window.location = "/cobros";
            }, 3000);
      };

      const printDocument = () => {
            const pdf = new jsPDF("p", "mm", "a4");
            const input = document.getElementById("content");
            html2canvas(input).then((canvas) => {
                  let imgWidth = 208;
                  let imgHeight = (canvas.height * imgWidth) / canvas.width;
                  const imgData = canvas.toDataURL("image/jpeg", 1.0);
                  pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

                  pdf.save(`labor.pdf`);
            });
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const monthNames = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
      ];

      const getLongMonthName = (date) => {
            //OBTIENE EL NOMBRE LARGO DEL MES
            return monthNames[date.getMonth()];
      };

      return (
            <>
                  <Header />
                  <ThemeProvider theme={theme}>
                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              alignItems="center"
                              style={{ marginTop: "70px" }}
                        >
                              <div style={{ marginTop: 15 }}>
                                    <Button
                                          onClick={() => generarPDF()}
                                          style={{
                                                color: "white",
                                                background: "#ff0000",
                                          }}
                                          variant="contained"
                                    >
                                          Generar Recibo
                                    </Button>
                              </div>
                        </Grid>
                        <div
                              id="content"
                              style={{
                                    paddingTop: 20,
                                    marginBottom: "20px",
                              }}
                        >
                              <Container>
                                    <Grid
                                          container
                                          justifyContent="center"
                                          md={12}
                                          alignItems="center"
                                    >
                                          <Grid
                                                container
                                                justifyContent="space-between"
                                                md={11}
                                                sm={12}
                                          >
                                                <Grid item xs={6} sm={3} md={4}>
                                                      <img
                                                            src={
                                                                  process.env
                                                                        .PUBLIC_URL +
                                                                  "/images/logoEmpresa.png"
                                                            }
                                                            width="100%"
                                                      />
                                                      <div
                                                            style={{
                                                                  width: "100%",
                                                            }}
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        NIT :
                                                                        901365548
                                                                        - 9
                                                                  </b>
                                                            </Typography>
                                                      </div>
                                                </Grid>

                                                <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                >
                                                      <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            align="center"
                                                            style={{
                                                                  marginTop:
                                                                        "12px",
                                                            }}
                                                      >
                                                            <b>
                                                                  LABOR DE
                                                                  COBROS HOY{" "}
                                                                  {fecha.getDate() +
                                                                        "/" +
                                                                        getLongMonthName(
                                                                              fecha
                                                                        ) +
                                                                        "/" +
                                                                        fecha.getFullYear()}
                                                            </b>
                                                      </Typography>
                                                </Grid>
                                          </Grid>

                                          <Grid
                                                container
                                                justifyContent="center"
                                                mt={3}
                                                md={11}
                                                sm={12}
                                          >
                                                <table className="table table-bordered table-sm">
                                                      <thead>
                                                            <tr
                                                                  style={{
                                                                        color: "white",
                                                                        background:
                                                                              "#ff0000",
                                                                  }}
                                                            >
                                                                  <th colSpan="1">
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    FECHA
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                                  <th colSpan="3">
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    DETALLE
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                                  <th colSpan="1">
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    VALOR
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                                  <th colSpan="1">
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    EMPLEADO
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                            </tr>
                                                      </thead>

                                                      <tbody>
                                                            {gastos.map((g) => (
                                                                  <tr>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {
                                                                                                g.Fecha
                                                                                          }
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="3"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    {
                                                                                          g.Detalle
                                                                                    }
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {formatterPeso.format(
                                                                                                g.Valor
                                                                                          )}
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {
                                                                                                g.Empleado
                                                                                          }
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            ))}
                                                      </tbody>
                                                </table>
                                          </Grid>

                                          <Grid
                                                container
                                                justifyContent="start"
                                                xs={12}
                                                md={11}
                                                sm={12}
                                          >
                                                <Grid
                                                      item
                                                      xs={5}
                                                      md={7}
                                                      style={{
                                                            marginTop: "10px",
                                                      }}
                                                >
                                                      <table className="table table-bordered table-sm  ">
                                                            <thead>
                                                                  <tr
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                  >
                                                                        <th colSpan="1">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          PAGOS
                                                                                          POR
                                                                                          EFECTIVO
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th colSpan="1">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          PAGOS
                                                                                          ELECTRONICOS
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </thead>

                                                            <tbody>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {" "}
                                                                                          {formatterPeso.format(
                                                                                                totalPagosEfectivo
                                                                                          )}
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {" "}
                                                                                          {formatterPeso.format(
                                                                                                totalPagosElectronicos
                                                                                          )}
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </tbody>
                                                      </table>
                                                </Grid>
                                          </Grid>

                                          <Grid
                                                container
                                                justifyContent="start"
                                                xs={12}
                                                md={11}
                                                sm={12}
                                          >
                                                <Grid
                                                      item
                                                      xs={5}
                                                      md={7}
                                                      style={{
                                                            marginTop: "10px",
                                                      }}
                                                >
                                                      <table className="table table-bordered table-sm  ">
                                                            <thead>
                                                                  <tr
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                  >
                                                                        <th colSpan="1">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          TOTAL
                                                                                          GASTOS
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th colSpan="3">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          TOTAL
                                                                                          COBROS
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th colSpan="1">
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          TOTAL
                                                                                          NETO
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </thead>

                                                            <tbody>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {" "}
                                                                                          {formatterPeso.format(
                                                                                                totalGastos
                                                                                          )}
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="3"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {formatterPeso.format(
                                                                                                totalPagos
                                                                                          )}
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>

                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {formatterPeso.format(
                                                                                                totalNeto
                                                                                          )}
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </tbody>
                                                      </table>
                                                </Grid>
                                          </Grid>

                                          <Grid
                                                container
                                                justifyContent="center"
                                                style={{ marginTop: "10px" }}
                                                md={11}
                                                sm={12}
                                          >
                                                <Typography
                                                      variant="subtitle2"
                                                      component="h4"
                                                      align="center"
                                                >
                                                      <b>
                                                            Oficina:
                                                            Urbanización La
                                                            Castilla, Cl. 11 B
                                                            #2J – 46, Florencia,
                                                            Caquetá 3152533653 -
                                                            3123881225
                                                      </b>
                                                </Typography>
                                          </Grid>
                                          <Typography
                                                variant="subtitle2"
                                                component="h4"
                                                align="center"
                                          >
                                                <b>
                                                      Parque Cementerio: Km 12
                                                      Tres esquinas, Vía
                                                      Florencia a Morelia
                                                      Caquetá
                                                </b>
                                          </Typography>
                                    </Grid>
                              </Container>
                        </div>
                  </ThemeProvider>
                  <Footer />
            </>
      );
};
export default ComprobanteLaborDiaria;
