import React, { useRef, useState } from "react";
import "./header.css";
import { Container } from "reactstrap";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = () => {
      const [anchorEl, setAnchorEl] = React.useState(false);
      const open = Boolean(anchorEl);
      const navigate = useNavigate();

      const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
            console.log(setAnchorEl);
      };
      const handleClose = () => {
            setAnchorEl(null);
      };

      const sessionClose = () => {
            sessionStorage.clear();
            navigate("/");
      };
      return (
            <header className="header">
                  <Container>
                        <div className="navigation">
                              <div className="logo">
                                    <a href="/#/es">
                                          <img
                                                src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/logoEmpresa.png"
                                                }
                                                width="210px"
                                          />
                                    </a>
                              </div>
                              <div>
                                    <div
                                          className="custom_search"
                                          id="basic-button"
                                          aria-controls={
                                                open ? "basic-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                                open ? "true" : undefined
                                          }
                                          onClick={handleClick}
                                    >
                                          <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                          >
                                                <path
                                                      fill="none"
                                                      d="M0 0h24v24H0z"
                                                />
                                                <path
                                                      d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                                                      fill="rgba(255,255,255,1)"
                                                />
                                          </svg>
                                          <b>
                                                {sessionStorage.getItem("user")}
                                          </b>
                                    </div>
                                    <Menu
                                          id="basic-menu"
                                          anchorEl={anchorEl}
                                          open={open}
                                          onClose={handleClose}
                                          MenuListProps={{
                                                "aria-labelledby":
                                                      "basic-button",
                                          }}
                                    >
                                          <MenuItem
                                                onClick={handleClose}
                                                sx={{
                                                      display: {
                                                            sm: "none",
                                                            md: "none",
                                                            lg: "none",
                                                      },
                                                }}
                                          >
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="24"
                                                      height="24"
                                                      style={{
                                                            marginRight: "2px",
                                                      }}
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                                {sessionStorage.getItem("user")}
                                          </MenuItem>
                                          <MenuItem onClick={sessionClose}>
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="24"
                                                      height="24"
                                                      style={{
                                                            marginRight: "2px",
                                                      }}
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                                Cerrar Sesión
                                          </MenuItem>
                                    </Menu>
                              </div>
                        </div>
                  </Container>
            </header>
      );
};
export default Header;
