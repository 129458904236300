// Home.js
import React from "react";

const Home = () => {
  return (
    <div>
      <h2>Bienvenido a la página de inicio</h2>
      {/* Puedes agregar más contenido aquí */}
    </div>
  );
};

export default Home;
