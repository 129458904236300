import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DataGrid } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales/esES";
import DateFnsUtils from "@date-io/date-fns";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import { Container, Grid, Typography, Button, Paper } from "@mui/material";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";
import PetsIcon from "@material-ui/icons/Pets";
import LoginIcon from "@mui/icons-material/Login";
import Header from "./Header/Header";
import Footer from "./footer/Footer";

const Modificarcontratos = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const fecha = new Date();
      const fechaActual =
            fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate() +
            " " +
            fecha.getHours() +
            ":" +
            fecha.getMinutes() +
            ":" +
            fecha.getSeconds();
      const [contratos, setContratos] = useState([]);
      const [contrato, setContrato] = useState({});
      const [openEditarMascota, setOpenEditarMascota] = useState(false);
      const [openEditarContratos, setOpenEditarContratos] = useState(false);
      const [openNuevaMascota, setOpenNuevaMascota] = useState(false);
      const [data, setData] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [mascotas, setMascotas] = useState([]);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(10);
      const [size, setSize] = useState(0);
      const [id, setId] = useState("");
      const { num } = useParams();
      const theme = createTheme();
      const miny = 8000;
      const tobby = 12000;
      const togo = 15000;
      const mamut = 20000;

      theme.typography.h6 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.8rem",
            },
      };

      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.5rem",
            },
      };

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.5rem",
            },
      };

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerContratos();
                  traerMascotas();
                  setActualizar(false);
            }
      }, [actualizar]);

      const traerContratos = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/obtenercontrato/${num}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setContratos(res);

                        res.map((r) => (setContrato(r), setId(r.Cedula)));
                  });
      };

      const traerMascotas = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/obtenerMascotas/${num}`
            )
                  .then((res) => res.json())
                  .then((res) => setMascotas(res));
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const columns = [
            {
                  field: "Nombre",
                  headerName: "Nombre",
                  width: 150,
                  headerAlign: "center",
            },

            {
                  field: "Tipo",
                  headerName: "Tipo",
                  width: 100,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Raza",
                  headerName: "Raza",
                  width: 130,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Sexo",
                  headerName: "Sexo",
                  width: 90,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Color",
                  headerName: "Color",
                  width: 90,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Edad",
                  headerName: "Edad",
                  width: 90,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Plan",
                  headerName: "Plan",
                  width: 150,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Valor",
                  headerName: "Valor",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{formatterPeso.format(params.row.Valor)}</b>
                        </Stack>
                  ),
            },
            {
                  field: "Estado",
                  headerName: "Estado",
                  width: 130,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <Chip
                                    style={{
                                          fontSize: "14px",
                                          color: "white",
                                          fontWeight: "bold",
                                          background: `${
                                                (params.row.Estado &&
                                                      "green") ||
                                                "#ff0000"
                                          }`,
                                    }}
                                    label={`${
                                          (params.row.Estado && "VIVO") ||
                                          "FALLECIDO"
                                    }`}
                              />
                        </div>
                  ),
            },

            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="center"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Modificar Mascota"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalEditarMascotas(
                                                      params.row
                                                )
                                          }
                                    >
                                          <EditIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={`Estado ${
                                          (params.row.Estado && "Vivo") ||
                                          "Fallecido"
                                    }`}
                              >
                                    <IconButton
                                          aria-label="details"
                                          // color="primary"
                                          size={"small"}
                                    >
                                          <Switch
                                                size="small"
                                                onClick={() =>
                                                      muerteMascota(params.row)
                                                }
                                                checked={params.row.Estado}
                                                style={{
                                                      color: `${
                                                            (params.row
                                                                  .Estado &&
                                                                  "green") ||
                                                            "#ff0000"
                                                      }`,
                                                }}
                                                inputProps={{
                                                      "aria-label":
                                                            "secondary checkbox",
                                                }}
                                          />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const planMiny = (values) => {
            values.Valor = miny * 12;
      };

      const planTobby = (values) => {
            values.Valor = tobby * 12;
      };
      const planTogo = (values) => {
            values.Valor = togo * 12;
      };
      const planMamut = (values) => {
            values.Valor = mamut * 12;
      };

      const adcMiny = (values) => {
            values.Valor = (miny / 2) * 12;
      };

      const adcTobby = (values) => {
            values.Valor = (tobby / 2) * 12;
      };
      const adcTogo = (values) => {
            values.Valor = (togo / 2) * 12;
      };
      const adcMamut = (values) => {
            values.Valor = (mamut / 2) * 12;
      };

      const monthNames = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
      ];

      const getLongMonthName = (date) => {
            //OBTIENE EL NOMBRE LARGO DEL MES
            return monthNames[date.getMonth()];
      };

      const abrirModalEditarMascotas = (mascota) => {
            setData(mascota);
            setOpenEditarMascota(!openEditarMascota);
      };

      const cerrarModalEditarMascotas = () => {
            setOpenEditarMascota(!openEditarMascota);
            setData("");
      };

      const abrirModalEditarContratos = () => {
            setData(contrato);
            setOpenEditarContratos(!openEditarContratos);
      };

      const cerrarModalEditarContratos = () => {
            setOpenEditarContratos(!openEditarContratos);
            // setData('')
      };

      const cambiarEstado = (mas) => {
            mas.Estado = false;
            const obj = {
                  Estado: mas.Estado,
            };
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/mascotasEstado/${mas.Id}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const modificarDatosContratos = (values) => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/modificar/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            setActualizar(!actualizar);
            setOpenEditarContratos(!openEditarContratos);
      };

      const modificarCostos = (valor) => {
            let valorTotal = contrato.valorTotal + valor; //Suma el valor actual de los planes mas el nuevo plan!!!!!
            let valorCuotas = valorTotal / 12;

            const obj = {
                  valorTotal: valorTotal,
                  valorCuotas: valorCuotas,
            };

            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/modificar/${contrato.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const modificarDatosMascotas = (values) => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/mascotasEstado/${data.Id}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            setActualizar(!actualizar);
            setOpenEditarMascota(!openEditarMascota);
      };

      const guardarMascotas = (mascota) => {
            modificarCostos(mascota.Valor);
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(mascota),
            };
            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/contratos/mascotas",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );
            setActualizar(true);
            setOpenNuevaMascota(!openNuevaMascota);
      };

      const guardarMascotaFallecida = (mas) => {
            const obj = {
                  Fecha: fechaActual,
                  IdMascota: mas.Id,
            };
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/fallecidos/registro",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );
      };

      const restarAlTotalPlanContrato = (mas) => {
            let valorTotal = contrato.valorTotal - mas.Valor; //Resta el valor actual de los planes debido a el fallecimiento!!!!!
            let valorCuotas = valorTotal / 12;

            const obj = {
                  valorTotal: valorTotal,
                  valorCuotas: valorCuotas,
            };

            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/modificar/${contrato.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const muerteMascota = (mas) => {
            restarAlTotalPlanContrato(mas);
            guardarMascotaFallecida(mas);
            cambiarEstado(mas);
            setActualizar(true);
      };

      return (
            <>
                  <Header />
                  <Container>
                        <ThemeProvider theme={theme}>
                              <Dialog
                                    open={openEditarContratos}
                                    onClose={openEditarContratos}
                              >
                                    <div
                                          style={{
                                                paddingLeft: "10px",
                                                paddingTop: "10px",
                                          }}
                                    >
                                          <DialogTitle>
                                                <Typography
                                                      variant="h5"
                                                      component="h3"
                                                      align="center"
                                                >
                                                      <b>
                                                            Información del
                                                            Contrato
                                                      </b>
                                                </Typography>
                                          </DialogTitle>
                                          <DialogContent>
                                                <Grid
                                                      container
                                                      justifyContent="center"
                                                      xs={12}
                                                      md={12}
                                                >
                                                      <Formik
                                                            initialValues={{
                                                                  NoContrato:
                                                                        data.NoContrato,
                                                                  FecInicial:
                                                                        new Date(
                                                                              data.FecInicial1
                                                                        ).setDate() +
                                                                        1,
                                                                  FecRenovacion:
                                                                        new Date(
                                                                              data.FecRenovacion1
                                                                        ),
                                                                  FecNacimiento:
                                                                        new Date(
                                                                              data.FecNacimiento1
                                                                        ),
                                                                  Nombres: data.Nombres,
                                                                  Cedula: data.Cedula,
                                                                  Sexo: data.Sexo,
                                                                  EstadoCivil:
                                                                        data.EstadoCivil,
                                                                  Direccion:
                                                                        data.Direccion,
                                                                  Barrio: data.Barrio,
                                                                  Telefono: data.Telefono,
                                                                  Email: data.Email,
                                                                  DiaPago: data.DiaPago,
                                                                  y: data.y,
                                                                  valorTotal:
                                                                        data.valorTotal,
                                                                  valorCuotas:
                                                                        data.valorCuotas,
                                                            }}
                                                            onSubmit={(
                                                                  values,
                                                                  { resetForm }
                                                            ) => {
                                                                  modificarDatosContratos(
                                                                        values
                                                                  );
                                                                  resetForm();
                                                            }}
                                                      >
                                                            {({
                                                                  errors,
                                                                  touched,
                                                                  values,
                                                                  handleBlur,
                                                                  handleChange,
                                                                  setFieldValue,
                                                            }) => (
                                                                  <Form>
                                                                        <Grid
                                                                              container
                                                                              spacing={
                                                                                    2
                                                                              }
                                                                              md={
                                                                                    12
                                                                              }
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              justifyContent="center"
                                                                              p={
                                                                                    1
                                                                              }
                                                                        >
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="NoContrato"
                                                                                          label="No. Contrato *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.NoContrato
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.NoContrato &&
                                                                                                      errors.NoContrato
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.NoContrato &&
                                                                                                errors.NoContrato
                                                                                          }
                                                                                          InputProps={{
                                                                                                readOnly: true,
                                                                                          }}
                                                                                    />
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                              >
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h4"
                                                                                    >
                                                                                          <b>
                                                                                                *
                                                                                                VIGENCIA
                                                                                          </b>
                                                                                    </Typography>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                              >
                                                                                    <MuiPickersUtilsProvider
                                                                                          utils={
                                                                                                DateFnsUtils
                                                                                          }
                                                                                          locale={
                                                                                                esLocale
                                                                                          }
                                                                                    >
                                                                                          <KeyboardDatePicker
                                                                                                size="small"
                                                                                                name="FecInicial"
                                                                                                format="dd/MM/yyyy"
                                                                                                margin="none"
                                                                                                label="Fecha Inicial *"
                                                                                                fullWidth
                                                                                                inputVariant="filled"
                                                                                                value={
                                                                                                      values.FecInicial
                                                                                                }
                                                                                                error={Boolean(
                                                                                                      touched.FecInicial &&
                                                                                                            errors.FecInicial
                                                                                                )}
                                                                                                helperText={
                                                                                                      touched.FecInicial &&
                                                                                                      errors.FecInicial
                                                                                                }
                                                                                                onChange={(
                                                                                                      value
                                                                                                ) =>
                                                                                                      setFieldValue(
                                                                                                            "FecInicial",
                                                                                                            value
                                                                                                      )
                                                                                                }
                                                                                                KeyboardButtonProps={{
                                                                                                      "aria-label":
                                                                                                            "change date",
                                                                                                }}
                                                                                          />
                                                                                    </MuiPickersUtilsProvider>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                              >
                                                                                    <MuiPickersUtilsProvider
                                                                                          utils={
                                                                                                DateFnsUtils
                                                                                          }
                                                                                          locale={
                                                                                                esLocale
                                                                                          }
                                                                                    >
                                                                                          <KeyboardDatePicker
                                                                                                size="small"
                                                                                                name="FecRenovacion"
                                                                                                format="dd/MM/yyyy"
                                                                                                margin="none"
                                                                                                label="Fecha de Renovación *"
                                                                                                fullWidth
                                                                                                inputVariant="filled"
                                                                                                value={
                                                                                                      values.FecRenovacion
                                                                                                }
                                                                                                error={Boolean(
                                                                                                      touched.FecRenovacion &&
                                                                                                            errors.FecRenovacion
                                                                                                )}
                                                                                                helperText={
                                                                                                      touched.FecRenovacion &&
                                                                                                      errors.FecRenovacion
                                                                                                }
                                                                                                onChange={(
                                                                                                      value
                                                                                                ) =>
                                                                                                      setFieldValue(
                                                                                                            "FecRenovacion",
                                                                                                            value
                                                                                                      )
                                                                                                }
                                                                                                KeyboardButtonProps={{
                                                                                                      "aria-label":
                                                                                                            "change date",
                                                                                                }}
                                                                                          />
                                                                                    </MuiPickersUtilsProvider>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                              >
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h4"
                                                                                    >
                                                                                          <b>
                                                                                                *
                                                                                                INFORMACIÓN
                                                                                                DEL
                                                                                                TITULAR
                                                                                          </b>
                                                                                    </Typography>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Nombres"
                                                                                          label="Nombre y Apellidos *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Nombres
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.Nombres &&
                                                                                                      errors.Nombres
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.Nombres &&
                                                                                                errors.Nombres
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Cedula"
                                                                                          label="Cedula *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Cedula
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.Cedula &&
                                                                                                      errors.Cedula
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.Cedula &&
                                                                                                errors.Cedula
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                              >
                                                                                    <MuiPickersUtilsProvider
                                                                                          utils={
                                                                                                DateFnsUtils
                                                                                          }
                                                                                          locale={
                                                                                                esLocale
                                                                                          }
                                                                                    >
                                                                                          <KeyboardDatePicker
                                                                                                size="small"
                                                                                                name="FecNacimiento"
                                                                                                format="dd/MM/yyyy"
                                                                                                margin="none"
                                                                                                label="Fecha de Nacimiento *"
                                                                                                fullWidth
                                                                                                inputVariant="filled"
                                                                                                value={
                                                                                                      values.FecNacimiento
                                                                                                }
                                                                                                error={Boolean(
                                                                                                      touched.FecNacimiento &&
                                                                                                            errors.FecNacimiento
                                                                                                )}
                                                                                                helperText={
                                                                                                      touched.FecNacimiento &&
                                                                                                      errors.FecNacimiento
                                                                                                }
                                                                                                onChange={(
                                                                                                      value
                                                                                                ) =>
                                                                                                      setFieldValue(
                                                                                                            "FecNacimiento",
                                                                                                            value
                                                                                                      )
                                                                                                }
                                                                                                KeyboardButtonProps={{
                                                                                                      "aria-label":
                                                                                                            "change date",
                                                                                                }}
                                                                                          />
                                                                                    </MuiPickersUtilsProvider>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <FormControl
                                                                                          variant="filled"
                                                                                          fullWidth
                                                                                          size="small"
                                                                                    >
                                                                                          <InputLabel id="demo-simple-select-filled-label">
                                                                                                Sexo
                                                                                                *
                                                                                          </InputLabel>
                                                                                          <Select
                                                                                                labelId="demo-simple-select-filled-label"
                                                                                                id="demo-simple-select-filled"
                                                                                                name="Sexo"
                                                                                                value={
                                                                                                      values.Sexo
                                                                                                }
                                                                                                onBlur={
                                                                                                      handleBlur
                                                                                                }
                                                                                                onChange={
                                                                                                      handleChange
                                                                                                }
                                                                                                error={Boolean(
                                                                                                      touched.Sexo &&
                                                                                                            errors.Sexo
                                                                                                )}
                                                                                                helperText={
                                                                                                      touched.Sexo &&
                                                                                                      errors.Sexo
                                                                                                }
                                                                                          >
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "M"
                                                                                                      }
                                                                                                >
                                                                                                      MASCULINO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "F"
                                                                                                      }
                                                                                                >
                                                                                                      FEMENINO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "OTRO"
                                                                                                      }
                                                                                                >
                                                                                                      OTRO
                                                                                                </MenuItem>
                                                                                          </Select>
                                                                                    </FormControl>
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <FormControl
                                                                                          variant="filled"
                                                                                          fullWidth
                                                                                          size="small"
                                                                                    >
                                                                                          <InputLabel id="demo-simple-select-filled-label">
                                                                                                Estado
                                                                                                Civil
                                                                                                *
                                                                                          </InputLabel>
                                                                                          <Select
                                                                                                labelId="demo-simple-select-filled-label"
                                                                                                id="demo-simple-select-filled"
                                                                                                name="EstadoCivil"
                                                                                                value={
                                                                                                      values.EstadoCivil
                                                                                                }
                                                                                                onBlur={
                                                                                                      handleBlur
                                                                                                }
                                                                                                onChange={
                                                                                                      handleChange
                                                                                                }
                                                                                                error={Boolean(
                                                                                                      touched.EstadoCivil &&
                                                                                                            errors.EstadoCivil
                                                                                                )}
                                                                                                helperText={
                                                                                                      touched.EstadoCivil &&
                                                                                                      errors.EstadoCivil
                                                                                                }
                                                                                          >
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "CASADO"
                                                                                                      }
                                                                                                >
                                                                                                      CASADO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "SOLTERO"
                                                                                                      }
                                                                                                >
                                                                                                      SOLTERO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "UNIÓN LIBRE"
                                                                                                      }
                                                                                                >
                                                                                                      UNIÓN
                                                                                                      LIBRE
                                                                                                </MenuItem>
                                                                                          </Select>
                                                                                    </FormControl>
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Direccion"
                                                                                          label="Dirección de Residencia *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Direccion
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.Direccion &&
                                                                                                      errors.Direccion
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.Direccion &&
                                                                                                errors.Direccion
                                                                                          }
                                                                                    />
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Barrio"
                                                                                          label="Barrio *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Barrio
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.Barrio &&
                                                                                                      errors.Barrio
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.Barrio &&
                                                                                                errors.Barrio
                                                                                          }
                                                                                    />
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Telefono"
                                                                                          label="Télefono *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Telefono
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.Telefono &&
                                                                                                      errors.Telefono
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.Telefono &&
                                                                                                errors.Telefono
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                                    justifyContent="start"
                                                                              >
                                                                                    <TextField
                                                                                          name="Email"
                                                                                          label="E-mail *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Email
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                              >
                                                                                    <Typography
                                                                                          variant="subtitle1"
                                                                                          component="h4"
                                                                                    >
                                                                                          <b>
                                                                                                *
                                                                                                COSTOS
                                                                                                DEL
                                                                                                PLAN
                                                                                          </b>
                                                                                    </Typography>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="valorTotal"
                                                                                          label="Total Plan *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.valorTotal
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.valorTotal &&
                                                                                                      errors.valorTotal
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.valorTotal &&
                                                                                                errors.valorTotal
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="valorCuotas"
                                                                                          label="Valor de Cuotas *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.valorCuotas
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.valorCuotas &&
                                                                                                      errors.valorCuotas
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.valorCuotas &&
                                                                                                errors.valorCuotas
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="y"
                                                                                          label="Meses *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="number"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.y
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.y &&
                                                                                                      errors.y
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.y &&
                                                                                                errors.y
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="DiaPago"
                                                                                          label="Dia de Pago *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="number"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.DiaPago
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.DiaPago &&
                                                                                                      errors.DiaPago
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.DiaPago &&
                                                                                                errors.DiaPago
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    style={{
                                                                                          marginTop:
                                                                                                "20px",
                                                                                          marginBottom:
                                                                                                "5px",
                                                                                    }}
                                                                                    container
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <Button
                                                                                          size="medium"
                                                                                          type="submit"
                                                                                          style={{
                                                                                                marginRight:
                                                                                                      "10px",
                                                                                                background:
                                                                                                      "#ff0000",
                                                                                                color: "white",
                                                                                          }}
                                                                                    >
                                                                                          Modificar
                                                                                    </Button>
                                                                                    <Button
                                                                                          style={{
                                                                                                background:
                                                                                                      "#ff0000",
                                                                                                color: "white",
                                                                                          }}
                                                                                          size="medium"
                                                                                          type="button"
                                                                                          onClick={() =>
                                                                                                setOpenEditarContratos(
                                                                                                      !openEditarContratos
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          Cancelar
                                                                                    </Button>
                                                                              </Grid>
                                                                        </Grid>
                                                                  </Form>
                                                            )}
                                                      </Formik>
                                                </Grid>
                                          </DialogContent>
                                          <DialogActions></DialogActions>
                                    </div>
                              </Dialog>

                              <Dialog
                                    open={openNuevaMascota}
                                    onClose={openNuevaMascota}
                              >
                                    <div
                                          style={{
                                                paddingLeft: "10px",
                                                paddingTop: "10px",
                                          }}
                                    >
                                          <DialogTitle>
                                                <Typography
                                                      variant="h5"
                                                      component="h3"
                                                      align="center"
                                                >
                                                      <b>Nueva Mascota</b>
                                                </Typography>
                                          </DialogTitle>
                                          <DialogContent>
                                                <Grid
                                                      container
                                                      justifyContent="center"
                                                      xs={12}
                                                      md={12}
                                                >
                                                      <Formik
                                                            initialValues={{
                                                                  Nombre: "",
                                                                  Tipo: "",
                                                                  Raza: "",
                                                                  Color: "",
                                                                  Sexo: "",
                                                                  Edad: "",
                                                                  IdPropietario:
                                                                        id,
                                                                  Plan: "",
                                                                  Valor: "",
                                                            }}
                                                            validationSchema={Yup.object().shape(
                                                                  {
                                                                        Nombre: Yup.string()
                                                                              .matches(
                                                                                    /^[A-Za-z\sÀ-ÿ]+$/,
                                                                                    "* Solo se admiten letras"
                                                                              )
                                                                              .required(
                                                                                    "* Requerido"
                                                                              ),

                                                                        Tipo: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Sexo: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Raza: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Color: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Sexo: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Edad: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                  }
                                                            )}
                                                            onSubmit={(
                                                                  values,
                                                                  { resetForm }
                                                            ) => {
                                                                  guardarMascotas(
                                                                        values
                                                                  );
                                                                  resetForm();
                                                            }}
                                                      >
                                                            {({
                                                                  errors,
                                                                  touched,
                                                                  values,
                                                                  handleBlur,
                                                                  handleChange,
                                                                  setFieldValue,
                                                            }) => (
                                                                  <Form>
                                                                        <Grid
                                                                              container
                                                                              spacing={
                                                                                    2
                                                                              }
                                                                              md={
                                                                                    12
                                                                              }
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              justifyContent="center"
                                                                              p={
                                                                                    1
                                                                              }
                                                                        >
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          id="Nombre"
                                                                                          name="Nombre"
                                                                                          label="Nombre *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Nombre
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <FormControl
                                                                                          variant="filled"
                                                                                          fullWidth
                                                                                          size="small"
                                                                                    >
                                                                                          <InputLabel id="demo-simple-select-filled-label">
                                                                                                Tipo
                                                                                                *
                                                                                          </InputLabel>
                                                                                          <Select
                                                                                                labelId="demo-simple-select-filled-label"
                                                                                                id="demo-simple-select-filled"
                                                                                                name="Tipo"
                                                                                                value={
                                                                                                      values.Tipo
                                                                                                }
                                                                                                onBlur={
                                                                                                      handleBlur
                                                                                                }
                                                                                                onChange={
                                                                                                      handleChange
                                                                                                }
                                                                                          >
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "PERRO"
                                                                                                      }
                                                                                                >
                                                                                                      PERRO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "GATO"
                                                                                                      }
                                                                                                >
                                                                                                      GATO
                                                                                                </MenuItem>
                                                                                          </Select>
                                                                                    </FormControl>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Raza"
                                                                                          label="Raza *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Raza
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Color"
                                                                                          label="Color *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Color
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Edad"
                                                                                          label="Edad *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Edad
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <FormControl
                                                                                          variant="filled"
                                                                                          fullWidth
                                                                                          size="small"
                                                                                    >
                                                                                          <InputLabel id="demo-simple-select-filled-label">
                                                                                                Sexo
                                                                                                *
                                                                                          </InputLabel>
                                                                                          <Select
                                                                                                labelId="demo-simple-select-filled-label"
                                                                                                id="demo-simple-select-filled"
                                                                                                name="Sexo"
                                                                                                value={
                                                                                                      values.Sexo
                                                                                                }
                                                                                                onBlur={
                                                                                                      handleBlur
                                                                                                }
                                                                                                onChange={
                                                                                                      handleChange
                                                                                                }
                                                                                          >
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "MACHO"
                                                                                                      }
                                                                                                >
                                                                                                      MACHO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "HEMBRA"
                                                                                                      }
                                                                                                >
                                                                                                      HEMBRA
                                                                                                </MenuItem>
                                                                                          </Select>
                                                                                    </FormControl>
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="start"
                                                                              >
                                                                                    <FormControl
                                                                                          variant="filled"
                                                                                          fullWidth
                                                                                          size="small"
                                                                                    >
                                                                                          <InputLabel htmlFor="grouped-select">
                                                                                                Planes
                                                                                          </InputLabel>
                                                                                          <Select
                                                                                                id="grouped-select"
                                                                                                name="Plan"
                                                                                                value={
                                                                                                      values.Plan
                                                                                                }
                                                                                                onBlur={
                                                                                                      handleBlur
                                                                                                }
                                                                                                onChange={
                                                                                                      handleChange
                                                                                                }
                                                                                          >
                                                                                                <ListSubheader>
                                                                                                      PLAN
                                                                                                      MINY
                                                                                                </ListSubheader>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "PLAN MINY"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            planMiny(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      PLAN
                                                                                                      MINY
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "ADICIONAL MINY"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            adcMiny(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      ADICIONAL
                                                                                                      MINY
                                                                                                </MenuItem>

                                                                                                <ListSubheader>
                                                                                                      PLAN
                                                                                                      TOBBY
                                                                                                </ListSubheader>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "PLAN TOBBY"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            planTobby(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      PLAN
                                                                                                      TOBBY
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "ADICIONAL TOBBY"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            adcTobby(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      ADICIONAL
                                                                                                      TOBBY
                                                                                                </MenuItem>

                                                                                                <ListSubheader>
                                                                                                      PLAN
                                                                                                      TOGO
                                                                                                </ListSubheader>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "PLAN TOGO"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            planTogo(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      PLAN
                                                                                                      TOGO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "ADICIONAL TOGO"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            adcTogo(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      ADICIONAL
                                                                                                      TOGO
                                                                                                </MenuItem>

                                                                                                <ListSubheader>
                                                                                                      PLAN
                                                                                                      MAMUT
                                                                                                </ListSubheader>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "PLAN MAMUT"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            planMamut(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      PLAN
                                                                                                      MAMUT
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "ADICIONAL MAMUT"
                                                                                                      }
                                                                                                      onClick={() =>
                                                                                                            adcMamut(
                                                                                                                  values
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      ADICIONAL
                                                                                                      MAMUT
                                                                                                </MenuItem>
                                                                                          </Select>
                                                                                    </FormControl>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Valor"
                                                                                          label="Valor *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="number"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Valor
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    style={{
                                                                                          marginTop:
                                                                                                "20px",
                                                                                          marginBottom:
                                                                                                "5px",
                                                                                    }}
                                                                                    container
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <Button
                                                                                          size="medium"
                                                                                          type="submit"
                                                                                          style={{
                                                                                                marginRight:
                                                                                                      "10px",
                                                                                                background:
                                                                                                      "#ff0000",
                                                                                                color: "white",
                                                                                          }}
                                                                                    >
                                                                                          Registrar
                                                                                    </Button>
                                                                                    <Button
                                                                                          style={{
                                                                                                background:
                                                                                                      "#ff0000",
                                                                                                color: "white",
                                                                                          }}
                                                                                          size="medium"
                                                                                          type="button"
                                                                                          onClick={() =>
                                                                                                setOpenNuevaMascota(
                                                                                                      !openNuevaMascota
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          Cancelar
                                                                                    </Button>
                                                                              </Grid>
                                                                        </Grid>
                                                                  </Form>
                                                            )}
                                                      </Formik>
                                                </Grid>
                                          </DialogContent>
                                          <DialogActions></DialogActions>
                                    </div>
                              </Dialog>

                              <Dialog open={openEditarMascota}>
                                    <div
                                          style={{
                                                paddingLeft: "10px",
                                                paddingTop: "10px",
                                          }}
                                    >
                                          <DialogTitle>
                                                <Typography
                                                      variant="h5"
                                                      align="center"
                                                >
                                                      <b>Datos de la Mascota</b>
                                                </Typography>
                                          </DialogTitle>
                                          <DialogContent>
                                                <Grid
                                                      container
                                                      justifyContent="center"
                                                      xs={12}
                                                      md={12}
                                                >
                                                      <Formik
                                                            initialValues={{
                                                                  Nombre: data.Nombre,
                                                                  Tipo: data.Tipo,
                                                                  Raza: data.Raza,
                                                                  Color: data.Color,
                                                                  Sexo: data.Sexo,
                                                                  Edad: data.Edad,
                                                                  Valor: data.Valor,
                                                            }}
                                                            validationSchema={Yup.object().shape(
                                                                  {
                                                                        Nombre: Yup.string()
                                                                              .matches(
                                                                                    /^[A-Za-z\sÀ-ÿ]+$/,
                                                                                    "* Solo se admiten letras"
                                                                              )
                                                                              .required(
                                                                                    "* Requerido"
                                                                              ),

                                                                        Tipo: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Sexo: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Raza: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Color: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Sexo: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                        Edad: Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                  }
                                                            )}
                                                            onSubmit={(
                                                                  values,
                                                                  { resetForm }
                                                            ) => {
                                                                  modificarDatosMascotas(
                                                                        values
                                                                  );
                                                                  resetForm();
                                                            }}
                                                      >
                                                            {({
                                                                  errors,
                                                                  touched,
                                                                  values,
                                                                  handleBlur,
                                                                  handleChange,
                                                                  setFieldValue,
                                                            }) => (
                                                                  <Form>
                                                                        <Grid
                                                                              container
                                                                              spacing={
                                                                                    2
                                                                              }
                                                                              md={
                                                                                    12
                                                                              }
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              justifyContent="center"
                                                                              p={
                                                                                    1
                                                                              }
                                                                        >
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          id="Nombre"
                                                                                          name="Nombre"
                                                                                          label="Nombre *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Nombre
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <FormControl
                                                                                          variant="filled"
                                                                                          fullWidth
                                                                                          size="small"
                                                                                    >
                                                                                          <InputLabel id="demo-simple-select-filled-label">
                                                                                                Tipo
                                                                                                *
                                                                                          </InputLabel>
                                                                                          <Select
                                                                                                labelId="demo-simple-select-filled-label"
                                                                                                id="demo-simple-select-filled"
                                                                                                name="Tipo"
                                                                                                value={
                                                                                                      values.Tipo
                                                                                                }
                                                                                                onBlur={
                                                                                                      handleBlur
                                                                                                }
                                                                                                onChange={
                                                                                                      handleChange
                                                                                                }
                                                                                          >
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "PERRO"
                                                                                                      }
                                                                                                >
                                                                                                      PERRO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "GATO"
                                                                                                      }
                                                                                                >
                                                                                                      GATO
                                                                                                </MenuItem>
                                                                                          </Select>
                                                                                    </FormControl>
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Raza"
                                                                                          label="Raza *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Raza
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Color"
                                                                                          label="Color *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Color
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>
                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <TextField
                                                                                          name="Edad"
                                                                                          label="Edad *"
                                                                                          fullWidth
                                                                                          margin="none"
                                                                                          variant="filled"
                                                                                          type="text"
                                                                                          size="small"
                                                                                          value={
                                                                                                values.Edad
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                    />
                                                                              </Grid>

                                                                              <Grid
                                                                                    item
                                                                                    xs={
                                                                                          12
                                                                                    }
                                                                                    sm={
                                                                                          12
                                                                                    }
                                                                                    md={
                                                                                          6
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <FormControl
                                                                                          variant="filled"
                                                                                          fullWidth
                                                                                          size="small"
                                                                                    >
                                                                                          <InputLabel id="demo-simple-select-filled-label">
                                                                                                Sexo
                                                                                                *
                                                                                          </InputLabel>
                                                                                          <Select
                                                                                                labelId="demo-simple-select-filled-label"
                                                                                                id="demo-simple-select-filled"
                                                                                                name="Sexo"
                                                                                                value={
                                                                                                      values.Sexo
                                                                                                }
                                                                                                onBlur={
                                                                                                      handleBlur
                                                                                                }
                                                                                                onChange={
                                                                                                      handleChange
                                                                                                }
                                                                                          >
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "MACHO"
                                                                                                      }
                                                                                                >
                                                                                                      MACHO
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            "HEMBRA"
                                                                                                      }
                                                                                                >
                                                                                                      HEMBRA
                                                                                                </MenuItem>
                                                                                          </Select>
                                                                                    </FormControl>
                                                                              </Grid>

                                                                              <Grid
                                                                                    style={{
                                                                                          marginTop:
                                                                                                "20px",
                                                                                          marginBottom:
                                                                                                "5px",
                                                                                    }}
                                                                                    container
                                                                                    md={
                                                                                          12
                                                                                    }
                                                                                    justifyContent="center"
                                                                              >
                                                                                    <Button
                                                                                          size="medium"
                                                                                          type="submit"
                                                                                          style={{
                                                                                                marginRight:
                                                                                                      "10px",
                                                                                                background:
                                                                                                      "#ff0000",
                                                                                                color: "white",
                                                                                          }}
                                                                                    >
                                                                                          Modificar
                                                                                    </Button>
                                                                                    <Button
                                                                                          style={{
                                                                                                background:
                                                                                                      "#ff0000",
                                                                                                color: "white",
                                                                                          }}
                                                                                          size="medium"
                                                                                          type="button"
                                                                                          onClick={() =>
                                                                                                cerrarModalEditarMascotas()
                                                                                          }
                                                                                    >
                                                                                          Cancelar
                                                                                    </Button>
                                                                              </Grid>
                                                                        </Grid>
                                                                  </Form>
                                                            )}
                                                      </Formik>
                                                </Grid>
                                          </DialogContent>
                                          <DialogActions></DialogActions>
                                    </div>
                              </Dialog>

                              <Grid
                                    container
                                    md={12}
                                    style={{ marginTop: "70px" }}
                              >
                                    {contratos.map((con) => (
                                          <table className="table table-bordered table-sm mt-4">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "#ff0000",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="5">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              INFORMACIÓN
                                                                              DEL
                                                                              CONTRATO
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr>
                                                            <th
                                                                  colSpan="1"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              NO
                                                                              CONTRATO:{" "}
                                                                              {
                                                                                    con.NoContrato
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              FECHA
                                                                              INICIAL:{" "}
                                                                        </b>
                                                                        {
                                                                              con.FecInicial
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              FECHA
                                                                              DE
                                                                              RENOVACIÓN:{" "}
                                                                        </b>
                                                                        {
                                                                              con.FecRenovacion
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="5"
                                                                  scope="rowgroup"
                                                                  style={{
                                                                        background:
                                                                              "#ff0000",
                                                                        color: "white",
                                                                  }}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              INFORMACIÓN
                                                                              DEL
                                                                              TITULAR
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="3"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              NOMBRES
                                                                              Y
                                                                              APELLIDOS:{" "}
                                                                        </b>
                                                                        {
                                                                              con.Nombres
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              C.C{" "}
                                                                        </b>
                                                                        {
                                                                              con.Cedula
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="1"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              FECHA
                                                                              DE
                                                                              NACIMIENTO:{" "}
                                                                        </b>
                                                                        {
                                                                              con.FecNacimiento
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              SEXO:{" "}
                                                                        </b>
                                                                        {
                                                                              con.Sexo
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              ESTADO
                                                                              CIVIL:{" "}
                                                                        </b>
                                                                        {
                                                                              con.EstadoCivil
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="3"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              DIRECCIÓN
                                                                              DE
                                                                              RESIDENCIA:{" "}
                                                                        </b>
                                                                        {con.Barrio +
                                                                              " " +
                                                                              con.Direccion}
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              TELEFÓNO:{" "}
                                                                        </b>
                                                                        {
                                                                              con.Telefono
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="3"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              EMAIL:{" "}
                                                                        </b>
                                                                        {
                                                                              con.Email
                                                                        }
                                                                  </Typography>
                                                            </th>

                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              FORMA
                                                                              DE
                                                                              PAGO:{" "}
                                                                        </b>
                                                                        {
                                                                              con.formaPago
                                                                        }
                                                                  </Typography>
                                                            </th>
                                                      </tr>

                                                      <tr>
                                                            <th
                                                                  colSpan="3"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              VALOR
                                                                              TOTAL
                                                                              DEL
                                                                              PLAN
                                                                              :{" "}
                                                                              {formatterPeso.format(
                                                                                    con.valorTotal
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              CUOTA
                                                                              INICIAL{" "}
                                                                        </b>
                                                                        {formatterPeso.format(
                                                                              con.cuotaInicial
                                                                        )}
                                                                        <b>
                                                                              {" "}
                                                                              y{" "}
                                                                        </b>
                                                                        {con.y}
                                                                        <b>
                                                                              {" "}
                                                                              cuotas
                                                                              de{" "}
                                                                              {formatterPeso.format(
                                                                                    con.valorCuotas
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="5"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                  >
                                                                        <b>
                                                                              DIA
                                                                              DE
                                                                              PAGO
                                                                              :{" "}
                                                                        </b>
                                                                        Los días{" "}
                                                                        <b>
                                                                              {
                                                                                    con.DiaPago
                                                                              }
                                                                        </b>{" "}
                                                                        de cada
                                                                        mes
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    ))}
                              </Grid>

                              <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    container
                                    justifyContent="start"
                              >
                                    <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Modificar Contrato"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                      marginRight: "10px",
                                                }}
                                                size={"medium"}
                                                onClick={() =>
                                                      abrirModalEditarContratos()
                                                }
                                          >
                                                <EditIcon />
                                          </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Agregar Mascota"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                      marginRight: "10px",
                                                }}
                                                size={"medium"}
                                                onClick={() =>
                                                      setOpenNuevaMascota(
                                                            !openNuevaMascota
                                                      )
                                                }
                                          >
                                                <PetsIcon />
                                          </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Regresar"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                }}
                                                size={"medium"}
                                                onClick={() =>
                                                      (window.location =
                                                            "/gestion")
                                                }
                                          >
                                                <LoginIcon />
                                          </IconButton>
                                    </Tooltip>
                              </Grid>

                              <Grid
                                    container
                                    justifyContent="center"
                                    md={12}
                                    style={{
                                          marginTop: "14px",
                                          marginBottom: "20px",
                                    }}
                              >
                                    <Typography variant="h6" component="h2">
                                          <b>Mascotas:</b>
                                    </Typography>
                                    <Grid item xs={12} md={12} mt={2}>
                                          <Paper>
                                                <DataGrid
                                                      getRowId={(row) => row.Id}
                                                      localeText={
                                                            esES.components
                                                                  .MuiDataGrid
                                                                  .defaultProps
                                                                  .localeText
                                                      }
                                                      autoHeight
                                                      pagination
                                                      pageSize={rowsPerPage}
                                                      onPageSizeChange={(
                                                            newPageSize
                                                      ) =>
                                                            setRowsPerPage(
                                                                  newPageSize
                                                            )
                                                      }
                                                      rowsPerPageOptions={[
                                                            10, 50, 100,
                                                      ]}
                                                      labelRowsPerPage={
                                                            "contratoss por pagina"
                                                      }
                                                      rowCount={size}
                                                      page={page}
                                                      onPageChange={(newPage) =>
                                                            setPage(newPage)
                                                      }
                                                      columns={columns}
                                                      rows={mascotas}
                                                      //loading
                                                />
                                          </Paper>
                                    </Grid>
                              </Grid>
                        </ThemeProvider>
                  </Container>
                  <Footer />
            </>
      );
};

export default Modificarcontratos;
