import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import { Container, Grid, Typography, Button, Paper } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import "chartjs-plugin-labels";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./footer/Footer";

const GraficasEstadisticas = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [cobros, setCobros] = useState("");
      const [cobrosPrima, setCobrosPrima] = useState("");
      const [cobrosFallecidos, setCobrosFallecidos] = useState("");
      const [gastos, setGastos] = useState("");
      const [contratos, setContratos] = useState("");
      const [particulares, setParticulares] = useState("");
      const [cobrosPorDia, setCobrosPorDia] = useState([]);
      const [dia, setDias] = useState([]);
      const [estado, setEstado] = useState([]);
      const [cantidad, setCantidad] = useState([]);
      const [valor, setValor] = useState([]);
      const [diasAfi, setDiasAfi] = useState([]);
      const [afiliaciones, setAfiliaciones] = useState([]);
      const [colores, setColores] = useState([]);
      const [ingresosEstimados, setIngresosEstimados] = useState("");
      const [noCobros, setNoCobros] = useState("");
      const [porcentaje, setPorcentaje] = useState([]);
      const [plan, setPlan] = useState([]);
      const [medio, setMedio] = useState([]);
      const [total, setTotal] = useState([]);

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerTotalClientes();
            }
      }, []);

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const traerNoCobros = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/nocobrosmes/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setNoCobros(r.Total));
                  });
      };

      const traerTotalCobros = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalPagosPorRango/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setCobros(r.TotalCobros));
                  });
      };

      const traerTotalCobrosPrima = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalPagosPrima/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setCobrosPrima(r.TotalCobros));
                  });
      };

      const traerTotalCobrosFallecidos = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalPagosFallecidos/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setCobrosFallecidos(r.TotalCobros));
                  });
      };

      const traerTotalGastos = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalGastosPorRango/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setGastos(r.TotalGastos));
                  });
      };

      const traerEntierrosParticulares = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/fallecidos/particulares/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setParticulares(r.ENTIERRO_PARTICULAR));
                  });
      };

      const traerEntierrosContratos = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/fallecidos/contratos/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setContratos(r.ENTIERRO_NORMAL));
                  });
      };

      const traerPorcentajeFallecidos = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/fallecidos/porcentajes/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        let auxPlan = [];
                        let auxPorcentaje = [];

                        res.map(
                              (r) => (
                                    auxPlan.push(r.Plan),
                                    auxPorcentaje.push(r.Porcentaje)
                              )
                        );
                        setPlan(auxPlan);
                        setPorcentaje(auxPorcentaje);
                  });
      };

      const traerTotalCobrosPorMesDia = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/totalPagosPorDiaMes/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        let auxCobros = [];
                        let auxDias = [];
                        let auxValor = [];

                        res.map(
                              (r) => (
                                    auxDias.push(r.Mes),
                                    auxCobros.push(r.TotalCobros),
                                    auxValor.push(r.Valor)
                              )
                        );

                        setDias(auxDias);
                        setCobrosPorDia(auxCobros);
                        setValor(auxValor);
                  });
      };

      const traerCantidadAfiliacionesDiaMes = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/cantidadAfiliaciones/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        let auxDias = [];
                        let auxCantidad = [];

                        res.map(
                              (r) => (
                                    auxDias.push(r.Mes),
                                    auxCantidad.push(r.TotalAfiliaciones)
                              )
                        );
                        setDiasAfi(auxDias);
                        setAfiliaciones(auxCantidad);
                  });
      };

      const traerTotalClientes = () => {
            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/contratos/estadosContratos"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        let auxCantidad = [];
                        let auxEstado = [];

                        res.map(
                              (r) => (
                                    auxEstado.push(r.Estado),
                                    auxCantidad.push(r.Cantidad)
                              )
                        );
                        setEstado(auxEstado);
                        setCantidad(auxCantidad);
                  });
      };

      const traerMediosPago = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/mediospago/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        let auxMedio = [];
                        let auxTotal = [];

                        res.map(
                              (r) => (
                                    auxMedio.push(r.MedioPago),
                                    auxTotal.push(r.Total)
                              )
                        );
                        setMedio(auxMedio);
                        setTotal(auxTotal);
                  });
      };

      const generarCaracter = () => {
            let caracter = [
                  "a",
                  "b",
                  "c",
                  "d",
                  "e",
                  "f",
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
            ];
            let numero = (Math.random() * 15).toFixed(0);
            return caracter[numero];
      };

      const colorHEX = () => {
            let color = "";
            for (let i = 0; i < 6; i++) {
                  color = color + generarCaracter();
            }

            return "#" + color;
      };

      const generarColores = () => {
            let colores = [];
            for (let i = 0; i < 100; i++) {
                  colores.push(colorHEX());
            }
            setColores(colores);
      };

      const data = {
            labels: [
                  "Total Cobros",
                  "Total Cobros Mes a Mes",
                  "Total Cobros x Entierros",
                  "Total Gastos",
            ],
            datasets: [
                  {
                        label: "Total",
                        data: [cobros, cobrosPrima, cobrosFallecidos, gastos],
                        backgroundColor: colores,
                        borderColor: colores,
                  },
            ],
      };

      const data1 = {
            labels: dia,
            datasets: [
                  {
                        label: "Se Cobró",
                        data: cobrosPorDia,
                        backgroundColor: colores,
                        borderColor: colores,
                        borderWidth: 1,
                        pointStyle: "circle",
                        pointRadius: 5,
                        pointHoverRadius: 8,
                  },
            ],
      };

      const data2 = {
            labels: estado,
            datasets: [
                  {
                        label: "Total",
                        data: cantidad,
                        backgroundColor: colores,
                        borderColor: colores,
                  },
            ],
      };

      const data3 = {
            labels: diasAfi,
            datasets: [
                  {
                        label: "Afiliaciones",
                        data: afiliaciones,
                        backgroundColor: colores,
                        borderColor: colores,
                        borderWidth: 1,
                        pointStyle: "circle",
                        pointRadius: 5,
                        pointHoverRadius: 8,
                  },
            ],
      };

      const data4 = {
            labels: dia,
            datasets: [
                  {
                        label: "Ingresó",
                        data: valor,
                        backgroundColor: colores,
                        borderColor: colores,
                        borderWidth: 1,
                        pointStyle: "circle",
                        pointRadius: 5,
                        pointHoverRadius: 8,
                  },
            ],
      };

      const data5 = {
            labels: ["Entierros por Contrato", "Entierros Particulares"],
            datasets: [
                  {
                        label: "Total Entierros",
                        data: [contratos, particulares],
                        backgroundColor: colores,
                        borderColor: colores,
                  },
            ],
      };

      const data6 = {
            labels: plan,
            datasets: [
                  {
                        label: "Total Entierros",
                        data: porcentaje,
                        backgroundColor: colores,
                        borderColor: colores,
                  },
            ],
      };

      const data7 = {
            labels: medio,
            datasets: [
                  {
                        label: "Total",
                        data: total,
                        backgroundColor: colores,
                        borderColor: colores,
                  },
            ],
      };

      const opciones = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                  title: {
                        display: false,
                        text: (ctx) =>
                              "Point Style: " +
                              ctx.chart.data.datasets[0].pointStyle,
                  },
            },
      };

      const traerDatos = (values) => {
            generarColores();
            traerTotalCobros(values);
            traerNoCobros(values);
            traerTotalCobrosPrima(values);
            traerTotalCobrosFallecidos(values);
            traerTotalGastos(values);
            traerMediosPago(values);
            traerTotalCobrosPorMesDia(values);
            traerCantidadAfiliacionesDiaMes(values);
            traerEntierrosContratos(values);
            traerEntierrosParticulares(values);
            traerPorcentajeFallecidos(values);
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Grid
                              container
                              md={12}
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                          variant="h5"
                                          component="h2"
                                          align="center"
                                          style={{
                                                marginTop: "12px",
                                                marginBottom: "12px",
                                          }}
                                    >
                                          <b>Gráficas Estadisticas</b>
                                    </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                    <Formik
                                          initialValues={{
                                                Fecha1: null,
                                                Fecha2: null,
                                          }}
                                          onSubmit={(values, { resetForm }) => {
                                                traerDatos(values);
                                                //  resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <Grid
                                                            container
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="Fecha1"
                                                                              format="dd/MM/yyyy"
                                                                              margin="normal"
                                                                              label="Fecha 1*"
                                                                              //fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha1
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha1",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="Fecha2"
                                                                              format="dd/MM/yyyy"
                                                                              margin="normal"
                                                                              label="Fecha 2*"
                                                                              // fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha2
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha2",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>

                                                            <Grid item md={12}>
                                                                  <Button
                                                                        variant="contained"
                                                                        size="medium"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                  >
                                                                        Filtrar
                                                                  </Button>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </Grid>
                        </Grid>
                        <Grid
                              container
                              xs={12}
                              sm={12}
                              md={12}
                              spacing={3}
                              style={{ marginBottom: "20px" }}
                        >
                              <Grid item xs={12} sm={12} md={8}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>Relación de Cobros y Gastos</b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="bar"
                                                data={data}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>Medios de Pago Mas Utilizados</b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="bar"
                                                data={data7}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>Relación de N° Cobros x Dia Mes</b>
                                    </Typography>
                                    <Typography
                                          variant="subtitle2"
                                          align="start"
                                    >
                                          <b>Dias Cobrados: {dia.length}</b>
                                    </Typography>
                                    <Typography
                                          variant="subtitle2"
                                          align="start"
                                    >
                                          <b>
                                                Media de Cobros Diarios:{" "}
                                                {Math.round(
                                                      noCobros / dia.length
                                                )}
                                          </b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="line"
                                                data={data1}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>Relación de Ingresos x Dia Mes</b>
                                    </Typography>
                                    <Typography
                                          variant="subtitle2"
                                          align="start"
                                    >
                                          <b>Dias Cobrados: {dia.length}</b>
                                    </Typography>
                                    <Typography
                                          variant="subtitle2"
                                          align="start"
                                    >
                                          <b>
                                                Media de Ingresos Diarios:{" "}
                                                {formatterPeso.format(
                                                      Math.round(
                                                            cobros / dia.length
                                                      )
                                                )}
                                          </b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="line"
                                                data={data4}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>
                                                Relación de Afiliaciones x Dia
                                                Mes
                                          </b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="line"
                                                data={data3}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>Fallecidos</b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="bar"
                                                data={data5}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>
                                                Porcentaje de Fallecidos con
                                                Relación a los Planes
                                          </b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="bar"
                                                data={data6}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6}>
                                    <Typography
                                          variant="subtitle1"
                                          align="center"
                                    >
                                          <b>Clientes</b>
                                    </Typography>
                                    <Paper
                                          elevation={3}
                                          style={{ padding: "20px" }}
                                    >
                                          <Chart
                                                type="bar"
                                                data={data2}
                                                options={opciones}
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default GraficasEstadisticas;
