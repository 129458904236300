import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import ListSubheader from "@material-ui/core/ListSubheader";

var mascotas = [];

const FormContratoMascotas = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const fecha = new Date();
      const fechaActual =
            fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate();
      const { cc } = useParams();
      const [visibleTable, setVisibleTable] = useState(false);
      const [visible, setVisible] = useState(false);
      const [valor, setValor] = useState(0);
      const [contrato, setContrato] = useState([]);
      const [numero, setNumero] = useState("");
      const miny = 8000;
      const tobby = 12000;
      const togo = 15000;
      const mamut = 20000;

      const [datos, setDatos] = useState({
            formaPago: "",
            valorTotal: "",
            cuotaInicial: "",
            y: "",
            valorCuotas: "",
            Observaciones: "",
            DiaPago: "",
            CuotasRestantes: "",
            Saldo: "",
            ProximoPago: "",
      });

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerContrato();
            }
      }, []);

      const planMiny = (values) => {
            values.Valor = miny * 12;
      };

      const planTobby = (values) => {
            values.Valor = tobby * 12;
      };
      const planTogo = (values) => {
            values.Valor = togo * 12;
      };
      const planMamut = (values) => {
            values.Valor = mamut * 12;
      };

      const adcMiny = (values) => {
            values.Valor = (miny / 2) * 12;
      };

      const adcTobby = (values) => {
            values.Valor = (tobby / 2) * 12;
      };
      const adcTogo = (values) => {
            values.Valor = (togo / 2) * 12;
      };
      const adcMamut = (values) => {
            values.Valor = (mamut / 2) * 12;
      };

      const traerContrato = () => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/ultimoContrato/${cc}`
            )
                  .then((res) => res.json())
                  .then((res) => setContrato(res));
      };

      const handleInputChange = (e) => {
            setDatos({
                  ...datos,
                  [e.target.name]: e.target.value,
            });
            console.log(datos);
      };

      const almacenarMascotas = (values, resetForm) => {
            console.log(numero);
            mascotas.push(values);
            setVisibleTable(true);
            sumarValorPlanes();
            console.log(mascotas);
            setVisible(false);
            resetForm();
      };

      const sumarValorPlanes = () => {
            let reduce = mascotas.reduce(
                  (acumulador, actual) => acumulador + actual.Valor,
                  0
            );
            setValor(reduce);
      };
      const guardarMascotas = (mascota) => {
            actualizarDatosContrato();
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(mascota),
            };
            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/contratos/mascotas",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              title: res,
                              icon: "success",
                              confirmButtonText: "Listo",
                              confirmButtonColor: "#4c4",
                        }).then((res) => {
                              if (res.isConfirmed) {
                                    window.location = `/contrato/${numero}`;
                              }
                        })
                  );
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            //FORMATEA UN ENTERO A VALOR DE PESO COLOMBIANO
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const actualizarDatosContrato = () => {
            datos.valorTotal = valor; //Resto la Cuota inicial que es gratis
            datos.valorCuotas =
                  (datos.valorTotal - datos.cuotaInicial) / datos.y;
            datos.CuotasRestantes = datos.y - 1; //Le resto uno al numero de cuotas pior el mes gratis
            datos.Saldo = datos.valorTotal - datos.valorCuotas; //Resto la Cuota inicial que es gratis
            datos.ProximoPago = fechaActual;

            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(datos),
            };
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/modificarContrato/${numero}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      return (
            <Box
                  sx={{
                        bgcolor: "white",
                        height: "100vh",
                        alignContent: "center",
                        justifyContent: "center",
                  }}
            >
                  <Grid
                        container
                        justifyContent="center"
                        xs={12}
                        sm={12}
                        md={12}
                        alignItems="center"
                  >
                        <Grid
                              container
                              justifyContent="center"
                              xs={10}
                              sm={10}
                              md={9}
                        >
                              <Formik
                                    initialValues={{
                                          IdPropietario: cc,
                                          Nombre: "",
                                          Tipo: "",
                                          Raza: "",
                                          Color: "",
                                          Edad: "",
                                          Sexo: "",
                                          Plan: "",
                                          Valor: "",
                                    }}
                                    onSubmit={(values, { resetForm }) => {
                                          if (mascotas.length === 0) {
                                                guardarMascotas(values);
                                          } else {
                                                mascotas.map((mascota) =>
                                                      guardarMascotas(mascota)
                                                );
                                          }

                                          resetForm();
                                    }}
                              >
                                    {({
                                          errors,
                                          touched,
                                          values,
                                          handleBlur,
                                          handleChange,
                                          setFieldValue,
                                          resetForm,
                                    }) => (
                                          <Form>
                                                <Grid
                                                      container
                                                      spacing={2}
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                      p={5}
                                                >
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h2"
                                                                  align="center"
                                                                  style={{
                                                                        marginTop:
                                                                              "9px",
                                                                        color: "#1b1b1b",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        CONTRATO
                                                                        DE
                                                                        AFILIACIÓN
                                                                        INDIVIDUAL
                                                                  </b>
                                                            </Typography>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <Typography
                                                                  variant="subtitle1"
                                                                  component="h4"
                                                            >
                                                                  <b>
                                                                        *
                                                                        MASCOTAS
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  id="Nombre"
                                                                  name="Nombre"
                                                                  label="Nombre *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Nombre
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="center"
                                                      >
                                                            <FormControl
                                                                  variant="filled"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Tipo *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Tipo"
                                                                        value={
                                                                              values.Tipo
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "PERRO"
                                                                              }
                                                                        >
                                                                              PERRO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "GATO"
                                                                              }
                                                                        >
                                                                              GATO
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  name="Raza"
                                                                  label="Raza *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Raza
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  name="Color"
                                                                  label="Color *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Color
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  name="Edad"
                                                                  label="Edad *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Edad
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="center"
                                                      >
                                                            <FormControl
                                                                  variant="filled"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Sexo *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Sexo"
                                                                        value={
                                                                              values.Sexo
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "MACHO"
                                                                              }
                                                                        >
                                                                              MACHO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "HEMBRA"
                                                                              }
                                                                        >
                                                                              HEMBRA
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="start"
                                                      >
                                                            <FormControl
                                                                  variant="filled"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <InputLabel htmlFor="grouped-select">
                                                                        Planes
                                                                  </InputLabel>
                                                                  <Select
                                                                        id="grouped-select"
                                                                        name="Plan"
                                                                        value={
                                                                              values.Plan
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                  >
                                                                        <ListSubheader>
                                                                              PLAN
                                                                              MINY
                                                                        </ListSubheader>
                                                                        <MenuItem
                                                                              value={
                                                                                    "PLAN MINY"
                                                                              }
                                                                              onClick={() =>
                                                                                    planMiny(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              PLAN
                                                                              MINY
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ADICIONAL MINY"
                                                                              }
                                                                              onClick={() =>
                                                                                    adcMiny(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              ADICIONAL
                                                                              MINY
                                                                        </MenuItem>

                                                                        <ListSubheader>
                                                                              PLAN
                                                                              TOBBY
                                                                        </ListSubheader>
                                                                        <MenuItem
                                                                              value={
                                                                                    "PLAN TOBBY"
                                                                              }
                                                                              onClick={() =>
                                                                                    planTobby(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              PLAN
                                                                              TOBBY
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ADICIONAL TOBBY"
                                                                              }
                                                                              onClick={() =>
                                                                                    adcTobby(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              ADICIONAL
                                                                              TOBBY
                                                                        </MenuItem>

                                                                        <ListSubheader>
                                                                              PLAN
                                                                              TOGO
                                                                        </ListSubheader>
                                                                        <MenuItem
                                                                              value={
                                                                                    "PLAN TOGO"
                                                                              }
                                                                              onClick={() =>
                                                                                    planTogo(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              PLAN
                                                                              TOGO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ADICIONAL TOGO"
                                                                              }
                                                                              onClick={() =>
                                                                                    adcTogo(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              ADICIONAL
                                                                              TOGO
                                                                        </MenuItem>

                                                                        <ListSubheader>
                                                                              PLAN
                                                                              MAMUT
                                                                        </ListSubheader>
                                                                        <MenuItem
                                                                              value={
                                                                                    "PLAN MAMUT"
                                                                              }
                                                                              onClick={() =>
                                                                                    planMamut(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              PLAN
                                                                              MAMUT
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ADICIONAL MAMUT"
                                                                              }
                                                                              onClick={() =>
                                                                                    adcMamut(
                                                                                          values
                                                                                    )
                                                                              }
                                                                        >
                                                                              ADICIONAL
                                                                              MAMUT
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </Grid>
                                                      {visible && (
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={4}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Valor"
                                                                        label="Valor *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="number"
                                                                        size="small"
                                                                        value={
                                                                              values.Valor
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                  />
                                                            </Grid>
                                                      )}
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            style={{
                                                                  justifyContent:
                                                                        "flex-start",
                                                            }}
                                                      >
                                                            <Tooltip
                                                                  title="Agregar Mascotas"
                                                                  aria-label="add"
                                                            >
                                                                  <Fab
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                        onClick={() =>
                                                                              almacenarMascotas(
                                                                                    values,
                                                                                    resetForm
                                                                              )
                                                                        }
                                                                        size="small"
                                                                  >
                                                                        <AddIcon />
                                                                  </Fab>
                                                            </Tooltip>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <Typography
                                                                  variant="subtitle1"
                                                                  component="h4"
                                                            >
                                                                  <b>* PAGOS</b>
                                                            </Typography>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="center"
                                                      >
                                                            <FormControl
                                                                  variant="filled"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Forma de
                                                                        Pago *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="formaPago"
                                                                        value={
                                                                              datos.formaPago
                                                                        }
                                                                        onChange={
                                                                              handleInputChange
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "ANUAL"
                                                                              }
                                                                        >
                                                                              ANUAL
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "CONTADO"
                                                                              }
                                                                        >
                                                                              CONTADO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "MENSUAL"
                                                                              }
                                                                        >
                                                                              MENSUAL
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="start"
                                                      >
                                                            <TextField
                                                                  name="valorTotal"
                                                                  label="Valor Total *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="number"
                                                                  size="small"
                                                                  value={valor}
                                                                  onChange={
                                                                        handleInputChange
                                                                  }
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="start"
                                                      >
                                                            <TextField
                                                                  name="cuotaInicial"
                                                                  label="Cuota Inicial $"
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  fullWidth
                                                                  value={
                                                                        datos.cuotaInicial
                                                                  }
                                                                  onChange={
                                                                        handleInputChange
                                                                  }
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="start"
                                                      >
                                                            <TextField
                                                                  size="small"
                                                                  margin="none"
                                                                  name="y"
                                                                  id="outlined-number"
                                                                  label="No° Cuotas"
                                                                  type="number"
                                                                  fullWidth
                                                                  InputLabelProps={{
                                                                        shrink: true,
                                                                  }}
                                                                  variant="filled"
                                                                  value={
                                                                        datos.y
                                                                  }
                                                                  onChange={
                                                                        handleInputChange
                                                                  }
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="start"
                                                      >
                                                            <TextField
                                                                  name="valorCuotas"
                                                                  label="Cuotas de $"
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="number"
                                                                  size="small"
                                                                  fullWidth
                                                                  value={Math.round(
                                                                        (valor -
                                                                              datos.cuotaInicial) /
                                                                              datos.y
                                                                  )}
                                                                  onChange={
                                                                        handleInputChange
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                            justifyContent="start"
                                                      >
                                                            <TextField
                                                                  fullWidth
                                                                  size="small"
                                                                  margin="none"
                                                                  name="DiaPago"
                                                                  id="outlined-number"
                                                                  label="Día de Pago x cada Mes *"
                                                                  type="number"
                                                                  InputLabelProps={{
                                                                        shrink: true,
                                                                  }}
                                                                  variant="filled"
                                                                  value={
                                                                        datos.DiaPago
                                                                  }
                                                                  onChange={
                                                                        handleInputChange
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  id="filled-multiline-flexible"
                                                                  name="Observaciones"
                                                                  label="Observaciones *"
                                                                  multiline
                                                                  variant="filled"
                                                                  fullWidth
                                                                  maxRows={4}
                                                                  value={
                                                                        datos.Observaciones
                                                                  }
                                                                  onChange={
                                                                        handleInputChange
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <table className="table table-bordered  table-hover table-sm  mt-5">
                                                                  <thead>
                                                                        <tr
                                                                              style={{
                                                                                    color: "white",
                                                                                    background:
                                                                                          "#ff0000",
                                                                              }}
                                                                        >
                                                                              <th colSpan="5">
                                                                                    <Typography
                                                                                          variant="subtitle2"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          <b>
                                                                                                INFORMACIÓN
                                                                                                DEL
                                                                                                TITULAR
                                                                                          </b>
                                                                                    </Typography>
                                                                              </th>
                                                                        </tr>
                                                                  </thead>
                                                                  {contrato.map(
                                                                        (
                                                                              con
                                                                        ) => (
                                                                              <tbody>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="2"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            NOMBRES
                                                                                                            Y
                                                                                                            APELLIDOS:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Nombres
                                                                                                      }
                                                                                                      {setNumero(
                                                                                                            con.NoContrato
                                                                                                      )}
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="3"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            C.C{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Cedula
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            FECHA
                                                                                                            DE
                                                                                                            NACIMIENTO:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.FecNacimiento
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            SEXO:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Sexo
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="2"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            ESTADO
                                                                                                            CIVIL:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.EstadoCivil
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="2"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            DIRECCIÓN
                                                                                                            DE
                                                                                                            RESIDENCIA:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Direccion
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="3"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            TELEFÓNO:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Telefono
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="3"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            EMAIL:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Email
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            PLAN
                                                                                                            EXEQUIAL:{" "}
                                                                                                      </b>
                                                                                                      {mascotas.map(
                                                                                                            (
                                                                                                                  m
                                                                                                            ) =>
                                                                                                                  m.Plan +
                                                                                                                  ", "
                                                                                                      )}
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="3"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            FORMA
                                                                                                            DE
                                                                                                            PAGO:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            datos.formaPago
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            VALOR
                                                                                                            TOTAL
                                                                                                            DEL
                                                                                                            PLAN
                                                                                                            :{" "}
                                                                                                      </b>{" "}
                                                                                                      {formatterPeso.format(
                                                                                                            valor
                                                                                                      )}
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="3"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            CUOTA
                                                                                                            INICIAL{" "}
                                                                                                      </b>
                                                                                                      {formatterPeso.format(
                                                                                                            datos.cuotaInicial
                                                                                                      )}
                                                                                                      <b>
                                                                                                            {" "}
                                                                                                            y{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            datos.y
                                                                                                      }
                                                                                                      <b>
                                                                                                            {" "}
                                                                                                            cuotas
                                                                                                            de{" "}
                                                                                                      </b>
                                                                                                      {formatterPeso.format(
                                                                                                            Math.round(
                                                                                                                  (valor -
                                                                                                                        datos.cuotaInicial) /
                                                                                                                        datos.y
                                                                                                            )
                                                                                                      )}
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="2"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            DIA
                                                                                                            DE
                                                                                                            PAGO
                                                                                                            :{" "}
                                                                                                      </b>
                                                                                                      Los
                                                                                                      días{" "}
                                                                                                      <b>
                                                                                                            {
                                                                                                                  datos.DiaPago
                                                                                                            }
                                                                                                      </b>{" "}
                                                                                                      de
                                                                                                      cada
                                                                                                      mes
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                              </tbody>
                                                                        )
                                                                  )}
                                                            </table>
                                                      </Grid>
                                                      {visibleTable && (
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <table className="table table-bordered table-sm  table-hover">
                                                                        <thead>
                                                                              <tr
                                                                                    style={{
                                                                                          color: "white",
                                                                                          background:
                                                                                                "#ff0000",
                                                                                    }}
                                                                              >
                                                                                    <th colSpan="8">
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      MASCOTAS
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              <tr>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      NOMBRE
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      TIPO
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      RAZA
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      SEXO
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      COLOR
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>

                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      EDAD
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      PLAN
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      VALOR
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                              </tr>
                                                                              {mascotas.map(
                                                                                    (
                                                                                          m
                                                                                    ) => (
                                                                                          <tr>
                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Nombre
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Tipo
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Raza
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Sexo
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Color
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>

                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Edad
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Plan
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                                <th
                                                                                                      colSpan="1"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {formatterPeso.format(
                                                                                                                  m.Valor
                                                                                                            )}
                                                                                                      </Typography>
                                                                                                </th>
                                                                                          </tr>
                                                                                    )
                                                                              )}
                                                                        </tbody>
                                                                  </table>
                                                            </Grid>
                                                      )}

                                                      <Grid
                                                            container
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                            style={{
                                                                  marginTop:
                                                                        "15px",
                                                                  marginBottom:
                                                                        "15px",
                                                            }}
                                                      >
                                                            <Button
                                                                  style={{
                                                                        color: "white",
                                                                        height: "40px",
                                                                        background:
                                                                              "#ff0000",
                                                                  }}
                                                                  variant="contained"
                                                                  size="small"
                                                                  type="submit"
                                                            >
                                                                  <Typography variant="h6">
                                                                        Registrar
                                                                  </Typography>
                                                            </Button>
                                                      </Grid>
                                                </Grid>
                                          </Form>
                                    )}
                              </Formik>
                        </Grid>
                  </Grid>
            </Box>
      );
};
export default FormContratoMascotas;
