import React, { Suspense } from "react";
import TablaContrato from "./components/tablaContrato";
import FormContrato from "./components/formContrato";
import FormContratoMascotas from "./components/formMascotas";
import { Routes, Route } from "react-router-dom";
import GestionContratos from "./components/gestionContratos";
import GestionContratosMora from "./components/gestionContratosMora";
import FormContratoPrenecesidad from "./components/formMascotasPrenecesidad";
import Opciones from "./components/opciones";
import CobrosHoy from "./components/cobrosHoy";
import ComprobantePago from "./components/comprobantePago";
import ComprobantePagoIndividual from "./components/comprobantePagoIndividual";
import FormProspectos from "./components/formProspectos";
import TablaContratoPrenesecidad from "./components/tablaContratoPrenesecidad";
import FormMascotasProspectos from "./components/formMascotasProspectos";
import GestionProspectos from "./components/gestionProspectos";
import LaborCobros from "./components/laborCobros";
import ComprobanteLaborDiaria from "./components/comprobanteLaborDiaria";
import GraficasEstadisticas from "./components/graficasCobros";
import ModificarContrato from "./components/modificarContrato";
import TodosPagosHoy from "./components/todosPagosHoy";
import MapaFallecidos from "./components/mapaFallecidos";
import ListaFallecidos from "./components/listaFallecidos";
import CampoA from "./components/campoA";
import CampoB from "./components/campoB";
import CampoC from "./components/campoC";
import CampoD from "./components/campoD";
import CampoE from "./components/campoE";
import FormServicioParticular from "./components/formServicioParticular";
import ClientesRetirados from "./components/clientesRetirados";
import Estimaciones from "./components/estimaciones";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import FormUsuario from "./components/Usuario/FormUsuario";
import Home from "./components/home";
import Layout from "./components/Layout";

function App() {
      return (
          
                  <Routes>

            

                       
                        <Route path="/" exact element={<Login />}></Route>
                        <Route path="/haaa" element={<Home />} />
                        <Route
                              path="/registro"
                              exact
                              element={<FormUsuario />}
                        ></Route>
                        <Route path="/es" exact element={<Dashboard />}></Route>
                        <Route
                              path="/afiliaciones"
                              exact
                              element={<FormContrato />}
                        ></Route>
                        <Route
                              path="/contrato/:num"
                              exact
                              element={<TablaContrato />}
                        ></Route>
                        <Route
                              path="/contratoPrenec/:num"
                              exact
                              element={<TablaContratoPrenesecidad />}
                        ></Route>
                        <Route
                              path="/mascotas/:cc"
                              exact
                              element={<FormContratoMascotas />}
                        ></Route>
                        <Route
                              path="/prenecesidad/:cc"
                              exact
                              element={<FormContratoPrenecesidad />}
                        ></Route>
                        <Route
                              path="/opciones/:cc"
                              exact
                              element={<Opciones />}
                        ></Route>
                        <Route
                              path="/gestion"
                              exact
                              element={<GestionContratos />}
                        ></Route>
                        <Route
                              path="/pagosmora"
                              exact
                              element={<GestionContratosMora />}
                        ></Route>
                        <Route
                              path="/cobros"
                              exact
                              element={<CobrosHoy />}
                        ></Route>
                        <Route
                              path="/comprobante/:numero"
                              exact
                              element={<ComprobantePago />}
                        ></Route>
                        <Route
                              path="/comprobanteIndividual/:numero"
                              exact
                              element={<ComprobantePagoIndividual />}
                        ></Route>
                        <Route
                              path="/prospectos"
                              exact
                              element={<FormProspectos />}
                        ></Route>
                        <Route
                              path="/prospMascotas/:cc"
                              exact
                              element={<FormMascotasProspectos />}
                        ></Route>
                        <Route
                              path="/gestprospectos"
                              exact
                              element={<GestionProspectos />}
                        ></Route>
                        <Route
                              path="/labor"
                              exact
                              element={<LaborCobros />}
                        ></Route>
                        <Route
                              path="/comprobantediario"
                              exact
                              element={<ComprobanteLaborDiaria />}
                        ></Route>
                        <Route
                              path="/graficas"
                              exact
                              element={<GraficasEstadisticas />}
                        ></Route>
                        <Route
                              path="/modificar/:num"
                              exact
                              element={<ModificarContrato />}
                        ></Route>
                        <Route
                              path="/todospagos"
                              exact
                              element={<TodosPagosHoy />}
                        ></Route>
                        <Route
                              path="/mapa"
                              exact
                              element={<MapaFallecidos />}
                        ></Route>
                        <Route
                              path="/fallecidos"
                              exact
                              element={<ListaFallecidos />}
                        ></Route>
                        <Route
                              path="/campoa"
                              exact
                              element={<CampoA />}
                        ></Route>
                        <Route
                              path="/campob"
                              exact
                              element={<CampoB />}
                        ></Route>
                        <Route
                              path="/campoc"
                              exact
                              element={<CampoC />}
                        ></Route>
                        <Route
                              path="/campod"
                              exact
                              element={<CampoD />}
                        ></Route>
                        <Route
                              path="/campoe"
                              exact
                              element={<CampoE />}
                        ></Route>
                        <Route
                              path="/servicioparticular"
                              exact
                              element={<FormServicioParticular />}
                        ></Route>
                        <Route
                              path="/retirados"
                              exact
                              element={<ClientesRetirados />}
                        ></Route>
                        <Route
                              path="/estimaciones"
                              exact
                              element={<Estimaciones />}
                        ></Route>

</Routes>
              
               
      );
}

export default App;
