import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField, Typography, Grid, Button, Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Swal from "sweetalert2";
import "./login.css";

const Login = () => {
      const [showPassword, setShowPassword] = useState(false);

      const handleMouseDownPassword = (event) => {
            event.preventDefault();
      };

      const login = async (values) => {
            console.log(values)
            await axios
                  .post(
                        "https://bakdejandohuellas-main-production.up.railway.app/usuarios/login",
                        values
                  )
                  .then((res) => {
                        const { data } = res;
                        console.log(data);
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: "Hola! " + data.usuario.User,
                              text: data.mensaje,
                              showConfirmButton: false,
                              timerProgressBar: true,
                              timer: 3000,
                        }).then(() => {
                              window.location = "/#/es";
                        });
                        sessionStorage.setItem("token", data?.usuario.token);
                        sessionStorage.setItem("idUser", data.usuario.Id);
                        sessionStorage.setItem("user", data.usuario.User);
                  })

                  .catch((error) => {

                        console.log(error)
                        const Toast = Swal.mixin({
                              toast: true,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: 2000,
                              timerProgressBar: true,
                        });

                        Toast.fire({
                              icon: "error",
                              title: "Ups! Ha ocurrido un error",
                              text: error,
                        });
                  });
      };

      return (
            <Box sx={{ bgcolor: "#800000", height: "100vh" }}>
                  <Grid
                        container
                        sx={{ height: "100vh" }}
                        justifyContent="center"
                        xs={12}
                        sm={12}
                        md={12}
                        alignItems="center"
                  >
                        <Grid item xs={10} sm={8} md={4}>
                              <Paper
                                    style={{
                                          background: "#fff",
                                          borderRadius: "15px",
                                    }}
                                    elevation={2}
                              >
                                    <Formik
                                          initialValues={{
                                                Correo: "",
                                                Password: "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Correo: Yup.string().required(
                                                      "* Ingrese su usuario"
                                                ),
                                                Password: Yup.string().required(
                                                      "* Ingrese su contraseña"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                login(values);
                                                // resetForm()
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form
                                                      style={{
                                                            padding: "40px",
                                                      }}
                                                >
                                                      <Grid
                                                            container
                                                            xs={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <img
                                                                  src={
                                                                        process
                                                                              .env
                                                                              .PUBLIC_URL +
                                                                        "/images/logoEmpresa.png"
                                                                  }
                                                                  width="190px"
                                                                  height="70px"
                                                                  style={{
                                                                        marginBottom:
                                                                              "30px",
                                                                  }}
                                                            />
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h2"
                                                            >
                                                                  <b>
                                                                        Ingresa
                                                                        con tu
                                                                        cuenta!
                                                                  </b>
                                                            </Typography>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  sx={{ mt: 2 }}
                                                            >
                                                                  <TextField
                                                                        sx={{
                                                                              "& label.Mui-focused":
                                                                                    {
                                                                                          color: "#800000",
                                                                                    },
                                                                              "& .MuiOutlinedInput-root":
                                                                                    {
                                                                                          "& fieldset":
                                                                                                {
                                                                                                      border: "solid 1px #463e3e",
                                                                                                },
                                                                                          "&:hover fieldset":
                                                                                                {
                                                                                                      border: "#800000 solid 2px",
                                                                                                },
                                                                                          "&.Mui-focused fieldset":
                                                                                                {
                                                                                                      borderColor:
                                                                                                            "#800000",
                                                                                                },
                                                                                    },
                                                                        }}
                                                                        name="Correo"
                                                                        label="Usuario *"
                                                                        error={Boolean(
                                                                              touched.Correo &&
                                                                                    errors.Correo
                                                                        )}
                                                                        helperText={
                                                                              touched.Correo &&
                                                                              errors.Correo
                                                                        }
                                                                        fullWidth
                                                                        //variant="standard"
                                                                        value={
                                                                              values.Correo
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        type="text"
                                                                        InputProps={{
                                                                              endAdornment:
                                                                                    (
                                                                                          <InputAdornment position="end">
                                                                                                <AccountCircle />
                                                                                          </InputAdornment>
                                                                                    ),
                                                                        }}
                                                                        // size='small'
                                                                  />
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  sx={{ mt: 2 }}
                                                            >
                                                                  <FormControl
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        sx={{
                                                                              "& label.Mui-focused":
                                                                                    {
                                                                                          color: "#800000",
                                                                                    },
                                                                              "& .MuiOutlinedInput-root":
                                                                                    {
                                                                                          "& fieldset":
                                                                                                {
                                                                                                      border: "solid 1px #463e3e",
                                                                                                },
                                                                                          "&:hover fieldset":
                                                                                                {
                                                                                                      border: "#800000 solid 2px",
                                                                                                },
                                                                                          "&.Mui-focused fieldset":
                                                                                                {
                                                                                                      borderColor:
                                                                                                            "#800000",
                                                                                                },
                                                                                    },
                                                                        }}
                                                                  >
                                                                        <InputLabel htmlFor="outlined-adornment-password">
                                                                              Password
                                                                              *
                                                                        </InputLabel>
                                                                        <OutlinedInput
                                                                              name="Password"
                                                                              error={Boolean(
                                                                                    touched.Password &&
                                                                                          errors.Password
                                                                              )}
                                                                              helperText={
                                                                                    touched.Password &&
                                                                                    errors.Password
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              id="outlined-adornment-password"
                                                                              type={
                                                                                    showPassword
                                                                                          ? "text"
                                                                                          : "password"
                                                                              }
                                                                              value={
                                                                                    values.Password
                                                                              }
                                                                              onChange={handleChange(
                                                                                    "Password"
                                                                              )}
                                                                              endAdornment={
                                                                                    <InputAdornment position="start">
                                                                                          <IconButton
                                                                                                aria-label="toggle password visibility"
                                                                                                onClick={() =>
                                                                                                      setShowPassword(
                                                                                                            !showPassword
                                                                                                      )
                                                                                                }
                                                                                                onMouseDown={
                                                                                                      handleMouseDownPassword
                                                                                                }
                                                                                                edge="end"
                                                                                          >
                                                                                                {showPassword ? (
                                                                                                      <VisibilityOff />
                                                                                                ) : (
                                                                                                      <Visibility />
                                                                                                )}
                                                                                          </IconButton>
                                                                                    </InputAdornment>
                                                                              }
                                                                              label="Password *"
                                                                        />
                                                                  </FormControl>
                                                            </Grid>

                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  sx={{ mt: 2 }}
                                                            >
                                                                  <Button
                                                                        fullWidth
                                                                        style={{
                                                                              background:
                                                                                    "#800000",
                                                                              color: "white",
                                                                              borderRadius:
                                                                                    "20px",
                                                                        }}
                                                                        size="large"
                                                                        type="submit"
                                                                  >
                                                                        Iniciar
                                                                        Sesion
                                                                  </Button>
                                                            </Grid>
                                                            <Grid
                                                                  container
                                                                  justifyContent="space-between"
                                                                  mt={2}
                                                            >
                                                                  <Link
                                                                        href="/registro"
                                                                        underline="none"
                                                                        sx={{
                                                                              color: "#800000",
                                                                        }}
                                                                  >
                                                                        Eres
                                                                        nuevo?
                                                                        Registrate
                                                                  </Link>
                                                                  <Link
                                                                        href="/principal"
                                                                        underline="none"
                                                                        sx={{
                                                                              color: "#800000",
                                                                        }}
                                                                  >
                                                                        Olvidaste
                                                                        tu
                                                                        constraseña?
                                                                  </Link>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </Paper>
                        </Grid>
                  </Grid>
            </Box>
      );
};

export default Login;
