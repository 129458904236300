import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import InputAdornment from "@mui/material/InputAdornment";
import { Container, Grid, Typography, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@material-ui/core/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Switch from "@material-ui/core/Switch";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { esES } from "@mui/x-data-grid/locales/esES";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import FlagIcon from "@mui/icons-material/Flag";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import EditIcon from "@mui/icons-material/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ChatIcon from "@material-ui/icons/Chat";
import HistoryIcon from "@mui/icons-material/History";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import { useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./Header/Header";

const GestionContratosMora = () => {
      const [contratos, setContratos] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [openState, setOpenState] = useState(false);
      const [openEditar, setOpenEditar] = useState(false);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [size, setSize] = useState(0);
      const [openMascotas, setOpenMascotas] = useState(false);
      const [mascotas, setMascotas] = useState([]);
      const [openAgendar, setOpenAgendar] = useState(false);
      const [data, setData] = useState([]);
      const [openHistorial, setOpenHistorial] = useState(false);
      const [pagos, setPagos] = useState([]);
      const [totalMora, setTotalMora] = useState("");
      const [anchorEl, setAnchorEl] = useState(null);
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const theme = createTheme();

      theme.typography.h5 = {
            fontSize: "1.5rem",
            "@media (max-width:600px)": {
                  fontSize: "1rem",
            },
      };

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  modificarEstadoPrenecesidad();
                  actualizarNoReagendas();
                  cambiarEstadoPagos();
                  traerTotalCobrosMora();
                  fetch(
                        "https://bakdejandohuellas-main-production.up.railway.app/contratos/cobrosmora"
                  )
                        .then((res) => res.json())
                        .then((res) => {
                              setSize(res.length);
                              setContratos(res);
                        });
                  setActualizar(false);
            }
      }, [actualizar]);

      const handlePopoverOpen = (date, e) => {
            setData(date);
            setAnchorEl(e.currentTarget);
      };

      const handlePopoverClose = () => {
            setData("");
            setAnchorEl(null);
      };

      const open = Boolean(anchorEl);

      const actualizarNoReagendas = () => {
            //ACTUALIZA EL ESTADO DE CONTROL
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/contratos/actualizarNoReagendas",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const modificarEstadoPrenecesidad = () => {
            //Modifica el estado de los planes PRENECESIDAD
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/contratos/modificarEstado",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };
      const traerTotalCobrosMora = () => {
            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/contratos/totalcobrosmora"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalMora(r.TOTAL_MORA));
                  });
      };

      const columns = [
            {
                  field: "NoContrato",
                  headerName: "No. Contrato",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{params.row.NoContrato}</b>
                        </Stack>
                  ),
            },

            {
                  field: "Nombres",
                  headerName: "Cliente",
                  width: 280,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Cedula",
                  headerName: "Cédula",
                  width: 110,
                  headerAlign: "center",
            },

            {
                  field: "Direccion",
                  headerName: "Dirección",
                  width: 210,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Telefono",
                  headerName: "Teléfono",
                  width: 110,
                  headerAlign: "center",
            },
            {
                  field: "DiaPago",
                  headerName: "Día de Pago",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <strong>{params.row.DiaPago}</strong>
                        </div>
                  ),
            },
            {
                  field: "FechaReprogramada",
                  headerName: "Re_Programado",
                  width: 130,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <strong>
                                    {(params.row.FechaReprogramada ===
                                          "00/00/0000" &&
                                          " ") ||
                                          params.row.FechaReprogramada}
                              </strong>
                        </div>
                  ),
            },

            {
                  field: "valorCuotas",
                  headerName: "Cuota",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <b>
                                    {formatterPeso.format(
                                          params.row.valorCuotas
                                    )}
                              </b>
                        </div>
                  ),
            },
            {
                  field: "Acumulado",
                  headerName: "Cuotas Acumuladas",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <b>
                                    {formatterPeso.format(params.row.Acumulado)}
                              </b>
                        </div>
                  ),
            },
            {
                  field: "EstadoPagos",
                  headerName: "Meses Colgados",
                  width: 160,
                  headerAlign: "center",

                  renderCell: (params) => (
                        <div
                              aria-owns={
                                    open ? "mouse-over-popover" : undefined
                              }
                              aria-haspopup="true"
                              onMouseEnter={(e) =>
                                    handlePopoverOpen(params.row, e)
                              }
                              onMouseLeave={handlePopoverClose}
                              style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "5px",
                                    fontSize: "14px",
                                    color: `${
                                          (params.row.MesesEnMora === 1 &&
                                                "black") ||
                                          "white"
                                    }`,

                                    background: `${
                                          (params.row.MesesEnMora === 0 &&
                                                " #2ed600") ||
                                          (params.row.MesesEnMora === 0 &&
                                                "blue") ||
                                          (params.row.MesesEnMora === 1 &&
                                                "yellow") ||
                                          (params.row.MesesEnMora === 2 &&
                                                "red") ||
                                          "red"
                                    }`,
                              }}
                        >
                              <b>
                                    {"EN MORA " +
                                          (params.row.MesesEnMora + 1) +
                                          " MES/ES"}
                              </b>
                        </div>
                  ),
            },
            {
                  field: "Estado",
                  headerName: "Estado",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <Chip
                                    style={{
                                          fontSize: "14px",
                                          color: "white",
                                          background: `${
                                                (params.row.Estado ===
                                                      "ACTIVO" &&
                                                      "green") ||
                                                (params.row.Estado ===
                                                      "DESAFILIADO" &&
                                                      "#ff0000") ||
                                                "#ff0000"
                                          }`,
                                    }}
                                    label={<b>{params.row.Estado}</b>}
                              />
                        </div>
                  ),
            },
            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 280,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Modificar Datos"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                (window.location = `modificar/${params.row.NoContrato}`)
                                          }
                                    >
                                          <EditIcon />
                                    </IconButton>
                              </Tooltip>

                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Ver Historial de Pagos"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalHistorial(params.row)
                                          }
                                    >
                                          <HistoryIcon />
                                    </IconButton>
                              </Tooltip>

                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Estados"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalEstados(params.row)
                                          }
                                    >
                                          <FlagIcon />
                                    </IconButton>
                              </Tooltip>

                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"ReAgendar Dia de Cobro"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalAgendar(params.row)
                                          }
                                    >
                                          <DateRangeIcon />
                                    </IconButton>
                              </Tooltip>

                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Ver Contrato"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                verContrato(
                                                      params.row.NoContrato
                                                )
                                          }
                                    >
                                          <PictureAsPdfIcon />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const cambiarEstadoPagos = () => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };
            fetch(
                  "https://bakdejandohuellas-main-production.up.railway.app/contratos/modificarEstadosPagos",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const verContrato = (Cedula) => {
            const ruta = `https://contrato.dejandohuellas.com.co/contrato/${Cedula}`;
            window.open(ruta, "_blank");
            window.focus();
      };

      const reAgendar = (values) => {
            console.log(values.ProximoPago);
            console.log(values.NoReagendas);
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            /*  if(data.NoReagendas >= 1){
                  fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/reAgendar2/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            }else{*/
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/reAgendar/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            /*  }*/

            setActualizar(true);
            setData("");
            setOpenAgendar(!openAgendar);
      };

      const cambiarEstado = (mas) => {
            mas.Estado = !mas.Estado;
            const obj = {
                  Estado: mas.Estado,
            };
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/mascotasEstado/${mas.Id}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
            setActualizar(true);
      };

      const cambiarEstadoContrato = (con) => {
            con.Estado = "COBRO HOY";
            const obj = {
                  Estado: con.Estado,
            };
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/contratosEstado/${con.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
            setActualizar(true);
      };

      const traerPagos = (NoContrato) => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/pagos/historial/${NoContrato}`
            )
                  .then((res) => res.json())
                  .then((res) => setPagos(res));
      };

      const handleChange = (e) => {
            filtrarContratos(e.target.value);
      };

      const filtrarContratos = (termino) => {
            if (termino === "") {
                  setActualizar(true);
            } else {
                  fetch(
                        `https://bakdejandohuellas-main-production.up.railway.app/contratos/filtroCobrosMorosos/${termino}`
                  )
                        .then((res) => res.json())
                        .then((res) => {
                              setSize(res.length);
                              setContratos(res);
                        });
                  filtroTotalCobrosMora(termino);
            }
      };

      //TRAER MASCOTAS DEL CLIENTE DE CONTRATO
      const traerMascotasContrato = (Cedula) => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/obtenerMascotas/${Cedula}`
            )
                  .then((res) => res.json())
                  .then((res) => setMascotas(res));
      };

      const abrirModalHistorial = (datos) => {
            setData(datos);
            traerPagos(datos.NoContrato);
            setOpenHistorial(!openHistorial);
      };

      const cerrarModalHistorial = () => {
            //setActualizar(!actualizar);
            setOpenHistorial(!openHistorial);
            setData("");
      };

      const abrirModalMascotas = (Cedula) => {
            traerMascotasContrato(Cedula);
            setOpenMascotas(!openMascotas);
      };

      const cerrarModalMascotas = () => {
            setActualizar(!actualizar);
            setOpenMascotas(!openMascotas);
            //setMascotas("");
      };

      const abrirModalAgendar = (dato) => {
            setData(dato);
            console.log(data);
            setOpenAgendar(!openAgendar);
      };

      const cerrarModalAgendar = () => {
            setOpenAgendar(!openAgendar);
            setData("");
      };

      const abrirModalEstados = (props) => {
            setData(props);
            setOpenState(!openState);
      };

      const cerrarModalEstados = () => {
            setOpenState(!openState);
            setData("");
      };

      const abrirModalEditar = (contrato) => {
            setData(contrato);
            setOpenEditar(!openEditar);
      };

      const cerrarModalEditar = () => {
            setOpenEditar(!openEditar);
            setData("");
      };

      const modificarEstado = (values) => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/contratosEstado/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
            setActualizar(!actualizar);
            setOpenState(!openState);
      };

      const modificarDatos = (values) => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/modificarContrato/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
            setActualizar(!actualizar);
            setOpenEditar(!openEditar);
      };

      const filtrarPorFechas = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/cobrosmorafecha/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);
                        setContratos(res);
                  });
      };

      const totalCobrosMoraXFechas = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/totalmoraxfecha/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalMora(r.TOTAL_MORA));
                  });
      };

      const filtroTotalCobrosMora = (termino) => {
            fetch(
                  `https://bakdejandohuellas-main-production.up.railway.app/contratos/filtroTotalMorosos/${termino}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalMora(r.TOTAL_MORA));
                  });
      };

      const filtrosXFechas = (values) => {
            filtrarPorFechas(values);
            totalCobrosMoraXFechas(values);
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });
      return (
            <>
                  <Header />
                  <Container>
                        <Popover
                              id="mouse-over-popover"
                              sx={{
                                    pointerEvents: "none",
                              }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                              }}
                              transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                        >
                              <Typography
                                    align="center"
                                    variant="subtitle2"
                                    sx={{ pr: 1, pl: 1, pt: 1 }}
                              >
                                    <b>EN MORA DESDE: </b>
                              </Typography>
                              <Typography align="center" variant="subtitle2">
                                    <b>{data.CopyProximoPago}</b>
                              </Typography>
                        </Popover>
                        <Dialog open={openEditar} onClose={openEditar}>
                              <div
                                    style={{
                                          paddingTop: "20px",
                                          paddingLeft: "20px",
                                    }}
                              >
                                    <DialogTitle>
                                          <Typography
                                                variant="h5"
                                                component="h3"
                                                align="center"
                                                style={{
                                                      marginBottom: "-10px",
                                                }}
                                          >
                                                <b>Datos del Contrato</b>
                                          </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                          <Grid
                                                container
                                                justifyContent="center"
                                                xs={12}
                                                md={12}
                                          >
                                                <Formik
                                                      initialValues={{
                                                            FecInicial: null,
                                                            FecRenovacion: null,
                                                            FecNacimiento:
                                                                  new Date(
                                                                        data.FecNacimiento
                                                                  ),
                                                            Nombres: data.Nombres,
                                                            Cedula: data.Cedula,
                                                            Sexo: data.Sexo,
                                                            EstadoCivil:
                                                                  data.EstadoCivil,
                                                            Direccion:
                                                                  data.Direccion,
                                                            Telefono: data.Telefono,
                                                            Email: data.Email,

                                                            formaPago: "",
                                                            valorTotal: "",
                                                            cuotaInicial: "",
                                                            y: "",
                                                            valorCuotas: "",
                                                            Observaciones:
                                                                  data.Observaciones,
                                                            DiaPago: "",
                                                      }}
                                                      validationSchema={Yup.object().shape(
                                                            {
                                                                  FecInicial:
                                                                        Yup.date()
                                                                              .nullable()
                                                                              .required(
                                                                                    "* Requerido"
                                                                              ),
                                                                  FecRenovacion:
                                                                        Yup.date()
                                                                              .nullable()
                                                                              .required(
                                                                                    "* Requerido"
                                                                              ),
                                                                  FecNacimiento:
                                                                        Yup.date()
                                                                              .nullable()
                                                                              .required(
                                                                                    "* Requerido"
                                                                              ),
                                                                  Nombres: Yup.string()
                                                                        .matches(
                                                                              /^[A-Za-z\sÀ-ÿ]+$/,
                                                                              "* Solo se admiten letras"
                                                                        )
                                                                        .required(
                                                                              "* Requerido"
                                                                        ),

                                                                  Cedula: Yup.number(
                                                                        "* Solo se admiten números"
                                                                  ).required(
                                                                        "* Requerido"
                                                                  ),
                                                                  Sexo: Yup.string().required(
                                                                        "* Requerido"
                                                                  ),
                                                                  EstadoCivil:
                                                                        Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                  Direccion:
                                                                        Yup.string().required(
                                                                              "* Requerido"
                                                                        ),
                                                                  Telefono: Yup.number(
                                                                        "* Solo se admiten números"
                                                                  ).required(
                                                                        "* Requerido"
                                                                  ),
                                                            }
                                                      )}
                                                      onSubmit={(
                                                            values,
                                                            { resetForm }
                                                      ) => {
                                                            modificarDatos(
                                                                  values
                                                            );
                                                            resetForm();
                                                      }}
                                                >
                                                      {({
                                                            errors,
                                                            touched,
                                                            values,
                                                            handleBlur,
                                                            handleChange,
                                                            setFieldValue,
                                                      }) => (
                                                            <Form>
                                                                  <Grid
                                                                        container
                                                                        spacing={
                                                                              2
                                                                        }
                                                                        md={12}
                                                                        xs={12}
                                                                        sm={12}
                                                                        justifyContent="center"
                                                                        p={5}
                                                                  >
                                                                        <Grid
                                                                              item
                                                                              md={
                                                                                    12
                                                                              }
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h4"
                                                                              >
                                                                                    <b>
                                                                                          *
                                                                                          VIGENCIA
                                                                                    </b>
                                                                              </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                        >
                                                                              <MuiPickersUtilsProvider
                                                                                    utils={
                                                                                          DateFnsUtils
                                                                                    }
                                                                                    locale={
                                                                                          esLocale
                                                                                    }
                                                                              >
                                                                                    <KeyboardDatePicker
                                                                                          size="small"
                                                                                          name="FecInicial"
                                                                                          format="dd/MM/yyyy"
                                                                                          margin="none"
                                                                                          label="Fecha Inicial *"
                                                                                          fullWidth
                                                                                          inputVariant="filled"
                                                                                          value={
                                                                                                values.FecInicial
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.FecInicial &&
                                                                                                      errors.FecInicial
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.FecInicial &&
                                                                                                errors.FecInicial
                                                                                          }
                                                                                          onChange={(
                                                                                                value
                                                                                          ) =>
                                                                                                setFieldValue(
                                                                                                      "FecInicial",
                                                                                                      value
                                                                                                )
                                                                                          }
                                                                                          KeyboardButtonProps={{
                                                                                                "aria-label":
                                                                                                      "change date",
                                                                                          }}
                                                                                    />
                                                                              </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                        >
                                                                              <MuiPickersUtilsProvider
                                                                                    utils={
                                                                                          DateFnsUtils
                                                                                    }
                                                                                    locale={
                                                                                          esLocale
                                                                                    }
                                                                              >
                                                                                    <KeyboardDatePicker
                                                                                          size="small"
                                                                                          name="FecRenovacion"
                                                                                          format="dd/MM/yyyy"
                                                                                          margin="none"
                                                                                          label="Fecha de Renovación *"
                                                                                          fullWidth
                                                                                          inputVariant="filled"
                                                                                          value={
                                                                                                values.FecRenovacion
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.FecRenovacion &&
                                                                                                      errors.FecRenovacion
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.FecRenovacion &&
                                                                                                errors.FecRenovacion
                                                                                          }
                                                                                          onChange={(
                                                                                                value
                                                                                          ) =>
                                                                                                setFieldValue(
                                                                                                      "FecRenovacion",
                                                                                                      value
                                                                                                )
                                                                                          }
                                                                                          KeyboardButtonProps={{
                                                                                                "aria-label":
                                                                                                      "change date",
                                                                                          }}
                                                                                    />
                                                                              </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    12
                                                                              }
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h4"
                                                                              >
                                                                                    <b>
                                                                                          *
                                                                                          INFORMACIÓN
                                                                                          DEL
                                                                                          TITULAR
                                                                                    </b>
                                                                              </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                              justifyContent="center"
                                                                        >
                                                                              <TextField
                                                                                    name="Nombres"
                                                                                    label="Nombre y Apellidos *"
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    variant="filled"
                                                                                    type="text"
                                                                                    size="small"
                                                                                    value={
                                                                                          values.Nombres
                                                                                    }
                                                                                    onBlur={
                                                                                          handleBlur
                                                                                    }
                                                                                    onChange={
                                                                                          handleChange
                                                                                    }
                                                                                    error={Boolean(
                                                                                          touched.Nombres &&
                                                                                                errors.Nombres
                                                                                    )}
                                                                                    helperText={
                                                                                          touched.Nombres &&
                                                                                          errors.Nombres
                                                                                    }
                                                                              />
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                              justifyContent="center"
                                                                        >
                                                                              <TextField
                                                                                    name="Cedula"
                                                                                    label="Cedula *"
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    variant="filled"
                                                                                    type="text"
                                                                                    size="small"
                                                                                    value={
                                                                                          values.Cedula
                                                                                    }
                                                                                    onBlur={
                                                                                          handleBlur
                                                                                    }
                                                                                    onChange={
                                                                                          handleChange
                                                                                    }
                                                                                    error={Boolean(
                                                                                          touched.Cedula &&
                                                                                                errors.Cedula
                                                                                    )}
                                                                                    helperText={
                                                                                          touched.Cedula &&
                                                                                          errors.Cedula
                                                                                    }
                                                                              />
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                        >
                                                                              <MuiPickersUtilsProvider
                                                                                    utils={
                                                                                          DateFnsUtils
                                                                                    }
                                                                                    locale={
                                                                                          esLocale
                                                                                    }
                                                                              >
                                                                                    <KeyboardDatePicker
                                                                                          size="small"
                                                                                          name="FecNacimiento"
                                                                                          format="dd/MM/yyyy"
                                                                                          margin="none"
                                                                                          label="Fecha de Nacimiento *"
                                                                                          fullWidth
                                                                                          inputVariant="filled"
                                                                                          value={
                                                                                                values.FecNacimiento
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.FecNacimiento &&
                                                                                                      errors.FecNacimiento
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.FecNacimiento &&
                                                                                                errors.FecNacimiento
                                                                                          }
                                                                                          onChange={(
                                                                                                value
                                                                                          ) =>
                                                                                                setFieldValue(
                                                                                                      "FecNacimiento",
                                                                                                      value
                                                                                                )
                                                                                          }
                                                                                          KeyboardButtonProps={{
                                                                                                "aria-label":
                                                                                                      "change date",
                                                                                          }}
                                                                                    />
                                                                              </MuiPickersUtilsProvider>
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                              justifyContent="center"
                                                                        >
                                                                              <FormControl
                                                                                    variant="filled"
                                                                                    fullWidth
                                                                                    size="small"
                                                                              >
                                                                                    <InputLabel id="demo-simple-select-filled-label">
                                                                                          Sexo
                                                                                          *
                                                                                    </InputLabel>
                                                                                    <Select
                                                                                          labelId="demo-simple-select-filled-label"
                                                                                          id="demo-simple-select-filled"
                                                                                          name="Sexo"
                                                                                          value={
                                                                                                values.Sexo
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.Sexo &&
                                                                                                      errors.Sexo
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.Sexo &&
                                                                                                errors.Sexo
                                                                                          }
                                                                                    >
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      "M"
                                                                                                }
                                                                                          >
                                                                                                MASCULINO
                                                                                          </MenuItem>
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      "F"
                                                                                                }
                                                                                          >
                                                                                                FEMENINO
                                                                                          </MenuItem>
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      "OTRO"
                                                                                                }
                                                                                          >
                                                                                                OTRO
                                                                                          </MenuItem>
                                                                                    </Select>
                                                                              </FormControl>
                                                                        </Grid>

                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                              justifyContent="center"
                                                                        >
                                                                              <FormControl
                                                                                    variant="filled"
                                                                                    fullWidth
                                                                                    size="small"
                                                                              >
                                                                                    <InputLabel id="demo-simple-select-filled-label">
                                                                                          Estado
                                                                                          Civil
                                                                                          *
                                                                                    </InputLabel>
                                                                                    <Select
                                                                                          labelId="demo-simple-select-filled-label"
                                                                                          id="demo-simple-select-filled"
                                                                                          name="EstadoCivil"
                                                                                          value={
                                                                                                values.EstadoCivil
                                                                                          }
                                                                                          onBlur={
                                                                                                handleBlur
                                                                                          }
                                                                                          onChange={
                                                                                                handleChange
                                                                                          }
                                                                                          error={Boolean(
                                                                                                touched.EstadoCivil &&
                                                                                                      errors.EstadoCivil
                                                                                          )}
                                                                                          helperText={
                                                                                                touched.EstadoCivil &&
                                                                                                errors.EstadoCivil
                                                                                          }
                                                                                    >
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      "CASADO"
                                                                                                }
                                                                                          >
                                                                                                CASADO
                                                                                          </MenuItem>
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      "SOLTERO"
                                                                                                }
                                                                                          >
                                                                                                SOLTERO
                                                                                          </MenuItem>
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      "UNIÓN LIBRE"
                                                                                                }
                                                                                          >
                                                                                                UNIÓN
                                                                                                LIBRE
                                                                                          </MenuItem>
                                                                                    </Select>
                                                                              </FormControl>
                                                                        </Grid>

                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                              justifyContent="center"
                                                                        >
                                                                              <TextField
                                                                                    name="Direccion"
                                                                                    label="Dirección de Residencia *"
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    variant="filled"
                                                                                    type="text"
                                                                                    size="small"
                                                                                    value={
                                                                                          values.Direccion
                                                                                    }
                                                                                    onBlur={
                                                                                          handleBlur
                                                                                    }
                                                                                    onChange={
                                                                                          handleChange
                                                                                    }
                                                                                    error={Boolean(
                                                                                          touched.Direccion &&
                                                                                                errors.Direccion
                                                                                    )}
                                                                                    helperText={
                                                                                          touched.Direccion &&
                                                                                          errors.Direccion
                                                                                    }
                                                                              />
                                                                        </Grid>

                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                              justifyContent="center"
                                                                        >
                                                                              <TextField
                                                                                    name="Telefono"
                                                                                    label="Télefono *"
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    variant="filled"
                                                                                    type="text"
                                                                                    size="small"
                                                                                    value={
                                                                                          values.Telefono
                                                                                    }
                                                                                    onBlur={
                                                                                          handleBlur
                                                                                    }
                                                                                    onChange={
                                                                                          handleChange
                                                                                    }
                                                                                    error={Boolean(
                                                                                          touched.Telefono &&
                                                                                                errors.Telefono
                                                                                    )}
                                                                                    helperText={
                                                                                          touched.Telefono &&
                                                                                          errors.Telefono
                                                                                    }
                                                                              />
                                                                        </Grid>
                                                                        <Grid
                                                                              item
                                                                              xs={
                                                                                    12
                                                                              }
                                                                              sm={
                                                                                    12
                                                                              }
                                                                              md={
                                                                                    6
                                                                              }
                                                                              justifyContent="start"
                                                                        >
                                                                              <TextField
                                                                                    name="Email"
                                                                                    label="E-mail *"
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    variant="filled"
                                                                                    type="text"
                                                                                    size="small"
                                                                                    value={
                                                                                          values.Email
                                                                                    }
                                                                                    onBlur={
                                                                                          handleBlur
                                                                                    }
                                                                                    onChange={
                                                                                          handleChange
                                                                                    }
                                                                              />
                                                                        </Grid>

                                                                        <Grid
                                                                              style={{
                                                                                    marginTop:
                                                                                          "15px",
                                                                                    marginBottom:
                                                                                          "15px",
                                                                              }}
                                                                              container
                                                                              md={
                                                                                    12
                                                                              }
                                                                              justifyContent="center"
                                                                        >
                                                                              <Button
                                                                                    className="p-button-info"
                                                                                    size="medium"
                                                                                    type="submit"
                                                                                    style={{
                                                                                          marginRight:
                                                                                                "20px",
                                                                                    }}
                                                                              >
                                                                                    Modificar
                                                                              </Button>
                                                                              <Button
                                                                                    className="p-button-info"
                                                                                    size="medium"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                          cerrarModalEditar()
                                                                                    }
                                                                              >
                                                                                    Cancelar
                                                                              </Button>
                                                                        </Grid>
                                                                  </Grid>
                                                            </Form>
                                                      )}
                                                </Formik>
                                          </Grid>
                                    </DialogContent>
                                    <DialogActions></DialogActions>
                              </div>
                        </Dialog>

                        <Dialog open={openAgendar} onClose={openAgendar}>
                              <div style={{ padding: "20px" }}>
                                    <Formik
                                          initialValues={{
                                                FechaReprogramada: null,
                                                ProximoPago: data.ProximoPago1,
                                                NoReagendas:
                                                      data.NoReagendas + 1,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                FechaReprogramada: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                reAgendar(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "-10px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Re-Agendar
                                                                        el Día
                                                                        de Pago
                                                                  </b>
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <MuiPickersUtilsProvider
                                                                  utils={
                                                                        DateFnsUtils
                                                                  }
                                                                  locale={
                                                                        esLocale
                                                                  }
                                                            >
                                                                  <KeyboardDatePicker
                                                                        size="medium"
                                                                        name="FechaReprogramada"
                                                                        format="dd/MM/yyyy"
                                                                        margin="normal"
                                                                        label="Fecha Reprogramada *"
                                                                        fullWidth
                                                                        // inputVariant="filled"
                                                                        value={
                                                                              values.FechaReprogramada
                                                                        }
                                                                        error={Boolean(
                                                                              touched.FechaReprogramada &&
                                                                                    errors.FechaReprogramada
                                                                        )}
                                                                        helperText={
                                                                              touched.FechaReprogramada &&
                                                                              errors.FechaReprogramada
                                                                        }
                                                                        onChange={(
                                                                              value
                                                                        ) =>
                                                                              setFieldValue(
                                                                                    "FechaReprogramada",
                                                                                    value
                                                                              )
                                                                        }
                                                                        KeyboardButtonProps={{
                                                                              "aria-label":
                                                                                    "change date",
                                                                        }}
                                                                  />
                                                            </MuiPickersUtilsProvider>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  style={{
                                                                        marginTop:
                                                                              "15px",
                                                                        marginBottom:
                                                                              "15px",
                                                                  }}
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Modificar
                                                                  </Button>
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        onClick={() =>
                                                                              cerrarModalAgendar()
                                                                        }
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>

                        <Dialog open={openMascotas} onClose={openMascotas}>
                              <div style={{ padding: "5px" }}>
                                    <DialogTitle>
                                          <div
                                                style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      justifyContent:
                                                            "flex-end",
                                                }}
                                          >
                                                <Tooltip
                                                      interactive
                                                      placement={"top"}
                                                      title={"Cerrar"}
                                                >
                                                      <IconButton
                                                            aria-label="details"
                                                            style={{
                                                                  color: "#ff0000",
                                                            }}
                                                            size={"medium"}
                                                            onClick={() =>
                                                                  cerrarModalMascotas()
                                                            }
                                                      >
                                                            <CancelRoundedIcon />
                                                      </IconButton>
                                                </Tooltip>
                                          </div>
                                          <Typography
                                                variant="h5"
                                                component="h3"
                                                align="center"
                                          >
                                                <b>Mascotas</b>
                                          </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                          <Grid
                                                container
                                                justifyContent="center"
                                                mt={2}
                                                md={12}
                                                sm={12}
                                          >
                                                <div class="table-responsive-sm">
                                                      <table className="table table-bordered border-primary table-hover table-sm table-light">
                                                            <thead>
                                                                  <tr
                                                                        style={{
                                                                              background:
                                                                                    "#ff0000",
                                                                              color: "white",
                                                                        }}
                                                                  >
                                                                        <th colSpan="9">
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Listado
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                                  <tr>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Nombre
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Raza
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Tipo
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Color
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>

                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Sexo
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Edad
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>

                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Plan
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Estado
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="1"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    style={{
                                                                                          fontSize: "13px",
                                                                                    }}
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          Opciones
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                                  {mascotas.map(
                                                                        (m) => (
                                                                              <tr>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                style={{
                                                                                                      fontSize: "13px",
                                                                                                }}
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      m.Nombre
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                style={{
                                                                                                      fontSize: "13px",
                                                                                                }}
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      m.Tipo
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                style={{
                                                                                                      fontSize: "13px",
                                                                                                }}
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      m.Raza
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                style={{
                                                                                                      fontSize: "13px",
                                                                                                }}
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      m.Color
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                style={{
                                                                                                      fontSize: "13px",
                                                                                                }}
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      m.Sexo
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                style={{
                                                                                                      fontSize: "13px",
                                                                                                }}
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      m.Edad
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                          style={{
                                                                                                alignContent:
                                                                                                      "center",
                                                                                          }}
                                                                                    >
                                                                                          <Typography
                                                                                                style={{
                                                                                                      fontSize: "13px",
                                                                                                }}
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      m.Plan
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <div
                                                                                                style={{
                                                                                                      width: "100%",
                                                                                                      display: "flex",
                                                                                                      justifyContent:
                                                                                                            "center",
                                                                                                      alignItems:
                                                                                                            "center",
                                                                                                }}
                                                                                          >
                                                                                                <Chip
                                                                                                      size="small"
                                                                                                      style={{
                                                                                                            color: "white",
                                                                                                            background: `${
                                                                                                                  (m.Estado &&
                                                                                                                        "green") ||
                                                                                                                  "#ff0000"
                                                                                                            }`,
                                                                                                      }}
                                                                                                      label={`${
                                                                                                            (m.Estado &&
                                                                                                                  "VIVO") ||
                                                                                                            "FALLECIDO"
                                                                                                      }`}
                                                                                                />
                                                                                          </div>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <div
                                                                                                style={{
                                                                                                      width: "100%",
                                                                                                      display: "flex",
                                                                                                      justifyContent:
                                                                                                            "center",
                                                                                                      alignItems:
                                                                                                            "center",
                                                                                                }}
                                                                                          >
                                                                                                <Tooltip
                                                                                                      interactive
                                                                                                      placement={
                                                                                                            "top"
                                                                                                      }
                                                                                                      title={`Estado ${
                                                                                                            (m.Estado &&
                                                                                                                  "Vivo") ||
                                                                                                            "Fallecido"
                                                                                                      }`}
                                                                                                >
                                                                                                      <IconButton
                                                                                                            aria-label="details"
                                                                                                            // color="primary"
                                                                                                            size={
                                                                                                                  "small"
                                                                                                            }
                                                                                                      >
                                                                                                            <Switch
                                                                                                                  size="small"
                                                                                                                  onClick={() =>
                                                                                                                        cambiarEstado(
                                                                                                                              m
                                                                                                                        )
                                                                                                                  }
                                                                                                                  checked={
                                                                                                                        m.Estado
                                                                                                                  }
                                                                                                                  style={{
                                                                                                                        color: `${
                                                                                                                              (m.Estado &&
                                                                                                                                    "green") ||
                                                                                                                              "#ff0000"
                                                                                                                        }`,
                                                                                                                  }}
                                                                                                                  inputProps={{
                                                                                                                        "aria-label":
                                                                                                                              "secondary checkbox",
                                                                                                                  }}
                                                                                                            />
                                                                                                      </IconButton>
                                                                                                </Tooltip>
                                                                                          </div>
                                                                                    </th>{" "}
                                                                              </tr>
                                                                        )
                                                                  )}
                                                            </tbody>
                                                      </table>
                                                </div>
                                          </Grid>
                                    </DialogContent>
                                    <DialogActions></DialogActions>
                              </div>
                        </Dialog>

                        <Dialog open={openState} onClose={openState}>
                              <div style={{ padding: "15px" }}>
                                    <Formik
                                          initialValues={{
                                                Estado: data.Estado,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Estado: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                modificarEstado(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            Estado del Contrato
                                                      </DialogTitle>

                                                      <DialogContent>
                                                            <FormControl
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Estado"
                                                                        value={
                                                                              values.Estado
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Estado &&
                                                                                    errors.Estado
                                                                        )}
                                                                        helperText={
                                                                              touched.Estado &&
                                                                              errors.Estado
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "ACTIVO"
                                                                              }
                                                                        >
                                                                              ACTIVO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "DESAFILIADO"
                                                                              }
                                                                        >
                                                                              DESAFILIADO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "EN MORA"
                                                                              }
                                                                        >
                                                                              EN
                                                                              MORA
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "10px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Modificar
                                                                  </Button>
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalEstados()
                                                                        }
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>

                              <DialogActions></DialogActions>
                        </Dialog>

                        <Dialog open={openHistorial} onClose={openHistorial}>
                              <div id="content" style={{ padding: "5px" }}>
                                    <DialogTitle>
                                          <div
                                                style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      justifyContent:
                                                            "flex-end",
                                                }}
                                          >
                                                <Tooltip
                                                      interactive
                                                      placement={"top"}
                                                      title={"Cerrar"}
                                                >
                                                      <IconButton
                                                            aria-label="details"
                                                            style={{
                                                                  color: "#ff0000",
                                                            }}
                                                            size={"medium"}
                                                            onClick={() =>
                                                                  cerrarModalHistorial()
                                                            }
                                                      >
                                                            <CancelRoundedIcon />
                                                      </IconButton>
                                                </Tooltip>
                                          </div>
                                          <Typography
                                                variant="h5"
                                                component="h3"
                                                align="center"
                                                style={{ marginBottom: "7px" }}
                                          >
                                                <b>
                                                      Historial de Pagos de{" "}
                                                      {data.Nombres}
                                                </b>
                                          </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                          <Stack
                                                sx={{ width: "100%" }}
                                                spacing={2}
                                          >
                                                {pagos.map((p) => (
                                                      <Alert
                                                            icon={
                                                                  <ChatIcon fontSize="inherit" />
                                                            }
                                                            severity="info"
                                                            action={
                                                                  <Button
                                                                        color="inherit"
                                                                        size="small"
                                                                  >
                                                                        X
                                                                  </Button>
                                                            }
                                                      >
                                                            <AlertTitle>
                                                                  Pagó:{" "}
                                                                  {p.Fecha}
                                                            </AlertTitle>
                                                            <AlertTitle>
                                                                  {formatterPeso.format(
                                                                        p.Valor
                                                                  )}
                                                            </AlertTitle>
                                                            {p.Detalle}
                                                            {/*   <strong>
                                                            check it out!
                                                </strong>*/}
                                                      </Alert>
                                                ))}
                                          </Stack>
                                    </DialogContent>
                                    <DialogActions></DialogActions>
                              </div>
                        </Dialog>

                        <Grid
                              container
                              md={12}
                              justifyContent="center"
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Typography
                                    variant="h5"
                                    component="h2"
                                    align="center"
                                    style={{ marginTop: "12px" }}
                              >
                                    <b>GESTIÓN DE CONTRATOS</b>
                              </Typography>
                        </Grid>
                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              style={{ marginBottom: "20px" }}
                        >
                              <Grid
                                    item
                                    xs={8}
                                    sm={8}
                                    md={4}
                                    justifyContent="start"
                              >
                                    <TextField
                                          name="filtro"
                                          label="Buscar..."
                                          size="small"
                                          onChange={handleChange}
                                          variant="standard"
                                          margin="normal"
                                          InputProps={{
                                                endAdornment: (
                                                      <InputAdornment position="start">
                                                            <SearchIcon />
                                                      </InputAdornment>
                                                ),
                                          }}
                                    />
                              </Grid>

                              <Grid
                                    item
                                    sm={4}
                                    xs={4}
                                    md={8}
                                    style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center",
                                    }}
                              >
                                    <ThemeProvider theme={theme}>
                                          <Typography variant="h5">
                                                <b>
                                                      EN MORA:{" "}
                                                      {formatterPeso.format(
                                                            totalMora
                                                      )}
                                                </b>
                                          </Typography>
                                    </ThemeProvider>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                    <Formik
                                          initialValues={{
                                                Fecha1: null,
                                                Fecha2: null,
                                          }}
                                          onSubmit={(values, { resetForm }) => {
                                                filtrosXFechas(values);
                                                //  resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <Grid
                                                            container
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="Fecha1"
                                                                              format="dd/MM/yyyy"
                                                                              margin="normal"
                                                                              label="Fecha 1*"
                                                                              //fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha1
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha1",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="Fecha2"
                                                                              format="dd/MM/yyyy"
                                                                              margin="normal"
                                                                              label="Fecha 2*"
                                                                              // fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha2
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha2",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>

                                                            <Grid item md={12}>
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginBottom:
                                                                                    "10px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                  >
                                                                        Filtrar
                                                                  </Button>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                    <Button
                                          variant="contained"
                                          size="small"
                                          type="submit"
                                          style={{
                                                marginBottom: "10px",
                                                color: "white",
                                                background: "#ff0000",
                                          }}
                                          onClick={() =>
                                                window.location.replace("")
                                          }
                                    >
                                          VER TODOS
                                    </Button>
                              </Grid>

                              <Grid item xs={12} md={12} mt={2}>
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) =>
                                                      row.NoContrato
                                                }
                                                autoHeight
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Cargos por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={contratos}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default GestionContratosMora;
