import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";

ReactDOM.render(
      <React.StrictMode>

            <HashRouter>
           
            <App />
           
            </HashRouter>
      </React.StrictMode>,
      document.getElementById("root")
);
reportWebVitals();
